import { ContentDataTypes, ContentStatesTypes } from '../content-configuration';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  SimpleChanges,
  SimpleChange,
  OnChanges,
} from '@angular/core';
import { DynamicContent } from '../dynamic-content';

@Component({
  selector: 'esg-content-list',
  templateUrl: './content-list.component.html',
  styles: [
    `
      .list-of-content {
        display: flex;
        flex-direction: column;
        gap: 48px;

        &.reorder {
          gap: 16px;
        }
      }
    `,
  ],
})
export class ContentListComponent implements OnChanges {
  @Input({ required: true }) contentItems!: DynamicContent[];
  @Input({ required: true }) onChange!: (
    contentId: string,
    isVisibleInReport: boolean,
    states: ContentStatesTypes,
    data?: ContentDataTypes
  ) => void;
  @Input() readMode = false;
  @Input() reorderMode = false;
  @Input() hasVisibilityOption = false;
  @Input() isAllContentNotVisible = false;
  @Input() toolbarTop = 85;
  @Output() onDelete = new EventEmitter<string>();

  activeContentId?: string;
  scrollOnViewCheck: boolean = false;

  constructor(private hostRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const items: SimpleChange = changes['contentItems'];
    if (!items || items.firstChange) return;
    else if (items.previousValue && items.previousValue.length < items.currentValue.length) {
      this.handleOnSetActiveId(items.currentValue[items.currentValue.length - 1].contentId);
      this.scrollOnViewCheck = true;
    }
  }

  ngAfterViewChecked() {
    if (this.scrollOnViewCheck) {
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    this.scrollOnViewCheck = false;
    this.hostRef.nativeElement.scrollIntoView(false);
  }

  trackByMethod(index: number, el: any): number {
    return el.contentId;
  }

  handleOnSetActiveId(value: string | undefined) {
    this.activeContentId = value;
  }
}
