<div class="container">
  <div *ngIf="!state.reportId" class="side-panel">
    <div class="back-btn">
      <button (click)="onBackBtnClick.emit()">
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 8L7.98 16L8.89333 15.1193L2.42 8.63267H21.3333V7.36667H2.42L8.89333 0.886667L7.98 0L0 8Z"
            fill="#010B41" />
        </svg>
      </button>
      <span>Template gallery</span>
    </div>
    <header>TEMPLATE</header>
    <div *ngIf="state.templateData?.type === 0">
      <esg-template-card title="Custom Blank" [showChooseBtn]="false" [blankReport]="true"></esg-template-card>
      <p class="description">
        This is an empty blank template. You’ll need to create every scope from scratch with fully customization of
        every single input.
      </p>
      <p class="description">Use this template if there are no other templates that covers your need.</p>
    </div>
    <div *ngIf="state.templateData?.type === 1">
      <esg-template-card [title]="state.templateData?.title || ''"
        [backgroundPictureUrl]="state.templateData?.backgroundPhotoUrl || ''"
        [shortDescription]="state.templateData?.shortDescription || ''"
        [longDescription]="state.templateData?.description || ''" [showChooseBtn]="false"
        [blankReport]="false"></esg-template-card>
    </div>
  </div>
  <div *ngIf="state.reportId" class="edit-report" [class.border]="showStickyDivider">Edit report settings</div>
  <div #main class="main">
    <div class="content" *ngIf="!isLoading">
      <div class="title">
        <header *ngIf="!state.reportId">What is the title of your new report?</header>
        <header *ngIf="state.reportId">Report Title</header>
        <esg-textbox placeholder="Type your title here" [required]="true" [value]="state.fc.title.value" size="large"
          (onChange)="handleTitleChange($event)"></esg-textbox>
      </div>

      <div class="report-scope">
        <header *ngIf="!state.reportId">Which is the reporting organization?</header>
        <header *ngIf="state.reportId">Reporting organization</header>
        <esg-select-tree [value]="state.fc.reportScope.value || undefined"
          [options]="state.organizationSubOrganizationOptions"
          (onChange)="handleReportScopeChange($event)"></esg-select-tree>
        <div class="inlude-sub-orgs" [class.disabled]="state.fc.inludeSubOrgs.disabled">
          <esg-checkbox [checked]="state.fc.inludeSubOrgs.value" [disabled]="state.fc.inludeSubOrgs.disabled"
            (onCheck)="handleIncludeSubOrgsChange()"></esg-checkbox>
          <span>Include Sub Organization</span>
        </div>
      </div>

      <div class="time-period">
        <header>What time period will the report cover?</header>
        <esg-time-period [timePeriod]="state.fc.timePeriod.value || undefined" [hidePeriodTypes]="
            state.reportVersion === reportVersionEnum.V2 ? [timePeriodTypeEnum.Monthly, timePeriodTypeEnum.Custom] : []
          " [showYearStartMonth]="state.reportVersion === reportVersionEnum.V1"
          [numberOfYearsBefore]="2"
          (onChange)="handleTimePeriodChange($event)"></esg-time-period>
      </div>
      <div class="writing-system">
        <header>Writing system for PDF Export</header>
        <esg-select [value]="state.fc.pdfWritingSystem.value || undefined" [options]="state.pdfWritingSystemOptions"
          (onChange)="handleWritingSystem($event)"></esg-select>
      </div>
      <div class="cover-photo">
        <ng-container *ngIf="!state.reportId">
          <header class="additional-info-header">Do you have a smashing cover photo?</header>
          <div class="additional-info">No worries, you can change cover photo whenever you like.</div>
        </ng-container>
        <header class="edit-header">COVER PHOTO PDF REPORT</header>
        <esg-upload-picture [uploadedPhotos]="state.fc.coverPhotoSquare.value ? [state.fc.coverPhotoSquare.value] : []"
          (onSelectFile)="state.uploadCoverSquarePhoto($event)"
          (onClearUploadedPhoto)="state.removeCoverSquarePhoto()"></esg-upload-picture>
        <header class="edit-header">COVER PHOTO WEBSITE REPORT</header>
        <esg-upload-picture [texts]="componentTextsForHtml"
          [uploadedPhotos]="state.fc.coverPhoto.value ? [state.fc.coverPhoto.value] : []"
          (onSelectFile)="state.uploadCoverPhoto($event)"
          (onClearUploadedPhoto)="state.removeCoverPhoto()"></esg-upload-picture>
      </div>
    </div>
    <div class="scroll-observer" esgIntersectionObserver [root]="main" [threshold]="0" rootMargin="0px 0px -103px 0px"
      (onIntersection)="handleStickyDivider($event)"></div>
    <div class="btn-line" [class.border]="showStickyDivider">
      <div class="btn-wrapper">
        <esg-button *ngIf="!state.reportId" [disabled]="!state.formgroup.valid || submitted"
          (onClick)="submitAddReport()">
          Let's go
        </esg-button>
        <esg-button *ngIf="state.reportId" [disabled]="!state.formgroup.valid || !state.formgroup.dirty || edited"
          (onClick)="submitEditReport()">
          Save Changes
        </esg-button>
      </div>
    </div>
  </div>
</div>
