import { Component, Input } from '@angular/core';
import { IndicatorDataValueType } from 'src/api-client/report-api.generated';
import { formatNumbersInString } from 'src/app/shared/utils/number-converters';
import { IKeyfigures, ITableKeyFigureValue } from '../section-content-preview/section-content-preview.component';
import {
  IndicatorPeriodLabelsUi,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';

interface TableUi {
  tHead: TrUi[];
  tBody: TrUi[];
}

interface TrUi {
  items: TrItemUi[];
}

interface TrItemUi {
  rowSpan?: number;
  colSpan?: number;
  content?: string;
  unit?: string;
  isNumber: boolean;
  backgroundColor?: string;
}

@Component({
  selector: 'esg-indicator-table-value-preview',
  templateUrl: './indicator-table-value-preview.component.html',
  styleUrls: ['./indicator-table-value-preview.component.scss'],
})
export class IndicatorTableValuePreviewComponent {
  @Input({ required: true }) tableValue!: IKeyfigures;
  @Input() indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };
  @Input() visibleColumns: VisibleValueColumn[] = [];
  @Input() active: boolean = false;

  labelTable?: TableUi;
  currentTable?: TableUi;
  previousTable?: TableUi;

  visibleColumnEnum = VisibleValueColumn;
  references: string = '';

  getFormattedNumbers = formatNumbersInString;

  ngOnInit(): void {
    this.references = this.tableValue.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ' | ') || '';

    this.labelTable = this.createLabelTable(this.tableValue);
    this.currentTable = this.createValueTable(
      this.tableValue,
      this.tableValue.currentValue.tableRows || [],
      this.indicatorPeriodLabels.currentLabel
    );
    this.previousTable = this.createValueTable(
      this.tableValue,
      this.tableValue.previousValue.tableRows || [],
      this.indicatorPeriodLabels.previousLabel
    );
  }

  isNaN(value: any): boolean {
    return isNaN(Number(value));
  }

  formatCellValue(cell: TrItemUi): string {
    if (cell.content === 'NaN' || (cell.content !== undefined && cell.content !== null && this.isNaN(cell.content))) {
      return '-';
    }
    return cell.isNumber
      ? `${this.getFormattedNumbers(cell.content || '', 0, 2)} ${cell.unit || ''}`
      : `${cell.content || ''} ${cell.unit || ''}`;
  }

  createLabelTable(data: IKeyfigures) {
    const tHead: TrUi[] = [{ items: [{ content: '', isNumber: false }] }];

    if (data.metadata.tableColumnsMetadata?.length) {
      const hasGroupLabel = data.metadata.tableColumnsMetadata.some(column => column.groupLabel);

      if (hasGroupLabel) {
        tHead.push({ items: [{ content: '', isNumber: false }] });
      }

      const firstColumn = data.metadata.tableColumnsMetadata[0];

      tHead.push({
        items: [
          {
            content: firstColumn.label || '',
            isNumber: firstColumn.type === IndicatorDataValueType.Numeric,
          },
        ],
      });
    }

    const tableRows = data.currentValue.tableRows ? data.currentValue.tableRows : data.previousValue.tableRows;

    const tBody: TrUi[] = (tableRows || []).map(row => ({
      items: row?.values?.length
        ? [
            {
              content: (row.values[0]?.value || '').toString(),
              isNumber: row.values[0]?.type === IndicatorDataValueType.Numeric,
            },
          ]
        : [],
    }));

    return { tHead, tBody };
  }

  createValueTable(data: IKeyfigures, tableRows: ITableKeyFigureValue[], periodLabel: string) {
    const tHead: TrUi[] = [];

    tHead.push({
      items: [
        {
          content: periodLabel,
          isNumber: false,
          colSpan: data.metadata.tableColumnsMetadata?.length ? data.metadata.tableColumnsMetadata.length - 1 : 1,
        },
      ],
    });

    const units: string[] = [];
    const groups: string[] = [];
    if (data.metadata.tableColumnsMetadata?.length) {
      const groupItems: TrItemUi[] = [];
      let currentGroup = undefined;
      let groupColSpan = 1;
      const items: TrItemUi[] = [];
      for (let i = 1; i < data.metadata.tableColumnsMetadata.length; i++) {
        if (data.metadata.tableColumnsMetadata[i].groupLabel !== currentGroup) {
          if (currentGroup) {
            groupItems.push({
              content: currentGroup,
              isNumber: false,
              colSpan: groupColSpan,
            });
          }
          currentGroup = data.metadata.tableColumnsMetadata[i].groupLabel;
          groupColSpan = 1;
        } else if (currentGroup) {
          groupColSpan++;
        }

        units.push(
          data.metadata.tableColumnsMetadata[i].unit === 'Number'
            ? ''
            : data.metadata.tableColumnsMetadata[i].unit || ''
        );
        groups.push(data.metadata.tableColumnsMetadata[i].groupLabel || '');

        items.push({
          content: data.metadata.tableColumnsMetadata[i].label || '',
          isNumber: data.metadata.tableColumnsMetadata[i].type === IndicatorDataValueType.Numeric,
        });
      }

      if (groupColSpan > 1)
        groupItems.push({
          content: currentGroup,
          isNumber: false,
          colSpan: groupColSpan,
        });

      if (groupItems.length) tHead.push({ items: groupItems });

      tHead.push({ items });
    }

    const tBody: TrUi[] = [];

    for (const row of tableRows) {
      const items: TrItemUi[] = [];

      if (row.values) {
        for (let j = 1; j < row.values.length; j++) {
          items.push({
            content: row.values[j]?.value?.toString(),
            unit: row.values[j]?.unitDetails || units[j - 1],
            isNumber: row.values[j]?.type === IndicatorDataValueType.Numeric,
          });
        }
      }
      tBody.push({ items });
    }
    return { tHead, tBody };
  }

  isColumnVisible(columnValue: string): boolean {
    if (!this.visibleColumns) return true;
    return this.visibleColumns.some(column => column === columnValue);
  }
}
