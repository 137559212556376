<div class="topic-toolbar">
  <div class="topic-toolbar-content">
    <esg-tooltip *ngIf="shouldShowTooltip" text="{{reportState.selectedTopic?.name}}" position="bottom" variant="dark" [underline]="false">
      <span class="topic-name tooltip" [class.fade]="state.reordering">{{ reportState.selectedTopic?.name || '' }}</span>
    </esg-tooltip>
    <span *ngIf="!shouldShowTooltip" class="topic-name tooltip" [class.fade]="state.reordering">{{ reportState.selectedTopic?.name || '' }}</span>
    <button
      class="topic-toolbar-button add-section"
      [class.fade]="state.reordering"
      (click)="state.handleOnAddHeader()">
      <esg-section-icon></esg-section-icon>
      <div class="label">Add section</div>
      <div *ngIf="state.topicTopicItems.length === 0 && !state.isLoading" class="show-empty-text">
        <div class="text">Click Add Section to start adding content</div>
        <esg-arrow-right-top></esg-arrow-right-top>
      </div>
    </button>
    <button *ngIf="!state.reordering" class="topic-toolbar-button" (click)="state.handleOnReordering()">
      <esg-drag-icon></esg-drag-icon>
      <div class="label">Organize</div>
    </button>
    <esg-button *ngIf="state.reordering" (click)="state.handleOnReorderCancel()">Done</esg-button>
  </div>
</div>
<div
  class="feed"
  cdkDropList
  (cdkDropListDropped)="state.handleOnMoveTopicItem($event)"
  [class.reordering]="state.reordering">
  @for (item of state.topicTopicItems; track item.id; let last = $last){ @defer (on viewport) {
  <esg-topic-item-content
    [id]="item.id"
    cdkDrag
    [cdkDragDisabled]="!state.reordering"
    class="topic-item"
    [topicItem]="item"
    [reportId]="state.reportId"
    [indicatorOptions]="state.indicatorOptions"
    [indicatorSingleValueOptions]="state.indicatorSingleValueOptions"
    [indicatorPeriodLabels]="state.indicatorPeriodLabels"
    [responsiblePersonOptions]="state.responsiblePersonOptions"
    [reorderMode]="state.reordering"
    [showEmptyText]="state.topicTopicItems.length === 1"
    [scrollIntoViewOnInit]="state.fragmentSectionId === item.id"
    [isLastItem]="last"
    (onDeleteHeader)="state.handleOnInitateDeleteTopicItem(item.id)"
    (onResponsiblePersonChange)="state.updateResponsiblePerson(item.id, $event)"
    (onUpdateVisibility)="state.updateVisibility(item.id, $event)"></esg-topic-item-content>
  } @placeholder {
  <esg-topic-item-placeholder [scrollIntoViewOnInit]="state.fragmentSectionId === item.id"></esg-topic-item-placeholder>
  } }
</div>
<esg-confirm-dialog
  *ngIf="state.removeTopicItemId"
  [message]="state.removeTopicItemMessage"
  (onCancel)="state.handleOnDeleteTopicItemCancel()"
  (onSubmit)="state.handleOnDeleteTopicItem()"></esg-confirm-dialog>
