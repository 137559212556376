import { Component } from '@angular/core';
import { IndicatorUi, IndicatorsListStateService } from './indicator-list-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'esg-indicators-list',
  templateUrl: './indicators-list.component.html',
  styleUrls: ['./indicators-list.component.scss'],
  providers: [IndicatorsListStateService],
})
export class IndicatorsListComponent {
  get isIndicatorDetailsOpen(): boolean {
    return this.state.selectedIndicator !== undefined;
  }

  constructor(public state: IndicatorsListStateService, private router: Router) {}

  ngOnInit(): void {}

  handleIndicatorClick(indicatorId: string) {
    const queryParams = { ...this.router.routerState.snapshot.root.queryParams };
    this.router.navigate([], { queryParams, fragment: indicatorId });
  }

  closeIndicatorDetails() {
    const queryParams = { ...this.router.routerState.snapshot.root.queryParams };
    this.router.navigate([], { queryParams, fragment: undefined });
  }

  trackByIndicator(index: number, indicator: IndicatorUi) {
    return indicator.id;
  }
}
