import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { IOption, paragraphStyleOptions } from 'src/app/static-data/options';

let BlockEmbed = Quill.import('blots/block/embed');

class DividerBlot extends BlockEmbed {}
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';

Quill.register(DividerBlot, true);

const Link = Quill.import('formats/link');
Link.sanitize = function (url: string | null) {
  if (url && !url.startsWith('https://') && !url.startsWith('http://')) return 'https://' + url;
  else return url;
};

@Component({
  selector: 'esg-text-editor',
  templateUrl: './text-editor.component.html',
})
export class TextEditorComponent implements OnInit {
  @Input({ required: true }) fc!: FormControl<string | null>;
  @Input() placeholder: string = 'Insert text here...';
  @Input() classes: string = '';
  @Input() editorStyles: Object = {};
  @Input() toolbarStyles: Object = {};
  @Input() readOnly: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() hasParagraphStyles: boolean = true;
  @Input() hasChatBotOption: boolean = false;
  @Input() hasVisibilityOption: boolean = false;
  @Input() isVisible: boolean = true;
  @Output() onFocus = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onGenerateText = new EventEmitter<string>();
  @Output() onUpdateVisibility = new EventEmitter<boolean>();

  formats = ['bold', 'italic', 'align', 'script', 'list', 'divider', 'link', 'header'];
  paragraphOptions = paragraphStyleOptions;
  paragraphOption: IOption = this.paragraphOptions[0];
  chatPrompt?: FormControl<string>;

  @ViewChild('editor', { static: false }) editor!: QuillEditorComponent;
  constructor() {}

  ngOnInit(): void {}

  setFocus(editorEvent: Quill) {
    if (this.autoFocus) {
      editorEvent.focus();
    }
  }

  handleOnDelete() {
    this.editor.quillEditor.blur();
    this.onDelete.emit();
  }

  handleAddLink() {
    const range = this.editor.quillEditor.getSelection(true);
    const text = this.editor.quillEditor.getText(range.index, range.length);
    if (range.length > 0) {
      let href = prompt('Enter the URL');
      this.editor.quillEditor.deleteText(range.index, range.length, (Quill as any).sources.USER);
      this.editor.quillEditor.insertText(range.index, text, 'link', href, (Quill as any).sources.USER);
      this.editor.quillEditor.setSelection(range.index + range.length, (Quill as any).sources.SILENT);
      this.forceChange();
    }
  }

  paragraphValueSync() {
    const formatValue = this.editor.quillEditor.getFormat()?.header?.toString() || '';
    if (formatValue !== this.paragraphOption.value) {
      this.paragraphOption =
        this.paragraphOptions.find(option => option.value === formatValue) || this.paragraphOptions[0];
    }
  }

  handleSetHeader(option: IOption) {
    if (option !== this.paragraphOption) {
      this.paragraphOption = option;
      this.editor.quillEditor.format('header', Number(option.value));
      this.forceChange();
    }
  }

  handleAddDivider() {
    const range = this.editor.quillEditor.getSelection(true);
    this.editor.quillEditor.insertText(range.index, '\n', (Quill as any).sources.USER);
    this.editor.quillEditor.insertEmbed(range.index + 1, 'divider', true, (Quill as any).sources.USER);
    this.editor.quillEditor.setSelection(range.index + 2, (Quill as any).sources.SILENT);
  }

  forceChange() {
    const range = this.editor.quillEditor.getSelection(true);
    this.editor.quillEditor.insertText(range.index, ' ', (Quill as any).sources.USER);
    this.editor.quillEditor.deleteText(range.index, 1, (Quill as any).sources.USER);
  }

  handleOnChatBotClick() {
    this.fc.disable();
    this.chatPrompt = new FormControl<string>('', { nonNullable: true });
  }

  handleOnChatBotCancel() {
    this.fc.enable();
    this.fc.setValue('');
    this.chatPrompt = undefined;
  }

  handleOnSubmitPrompt() {
    this.fc.enable();
    if (this.chatPrompt?.value) {
      this.onGenerateText.emit(this.chatPrompt?.value);
      this.chatPrompt = undefined;
    }
  }
}
