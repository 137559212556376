<div class="container">
  <ng-container *ngIf="!state.isCreatingPdf && !state.isCalculatingIndicators">
    <esg-report-structure
      *ngIf="!state.isLoadingReport && state.reportData"
      [reportData]="state.reportData"
      (onSelectTopic)="handleSelectTopic($event)"
      (addMainLevel)="handleAddMainLevel($event)"
      (onCreatePDF)="state.createPdf()"
      (onCreateSummaryPDF)="state.createPdf(true)"
      (onCalculateIndicators)="state.calculateAllIndicators()"></esg-report-structure>
    <esg-side-menu *ngIf="state.isLoadingReport || !state.reportData" [goBackUrl]="'/report'" [backButtonLabel]="'Back to all reports'" [showBackButton]="true"></esg-side-menu>
  </ng-container>

  <div class="main">
    <ng-container *ngIf="!state.isLoadingReport && !state.isCreatingPdf && !state.isCalculatingIndicators">
      <router-outlet></router-outlet>
    </ng-container>
    <esg-dot-flashing
      *ngIf="state.isLoadingReport && !state.isCreatingPdf && !state.isCalculatingIndicators"
      class="loader"
      label="We are loading navigation"></esg-dot-flashing>
    <esg-dot-flashing *ngIf="state.isCreatingPdf" class="loader" label="We are creating your PDF"></esg-dot-flashing>
    <esg-dot-flashing
      *ngIf="state.isCalculatingIndicators"
      class="loader"
      [label]="state.isCalculatingIndicatorsLabel"></esg-dot-flashing>
  </div>
</div>
