<div class="wrapper" [class.collapsed]="reorderMode" [class.active]="active">
  @if (reorderMode) {
  <esg-content-topbar [id]="contentId" cdkDrag [text]="indicatorLabels" (onDelete)="handleOnDelete()">
    <span cdkDragHandle handle class="topbar__handle">
      <esg-drag-icon></esg-drag-icon>
    </span>
    <esg-kpi-icon typeIcon></esg-kpi-icon>
  </esg-content-topbar>
  } @else {
  <div
    esg-on-click-outside
    (onClickOutside)="active && handleOutsideClick()"
    (click)="handleComponentClick()"
    class="container"
    [class.empty]="!active && (!data?.indicatorType || !states.indicatorValues.length)"
    [class.not-visible]="!isVisibleInReport">
    @if (active) {
    <esg-indicator-content-toolbar
      [visibleColumns]="visibleColumns"
      [columnOptions]="
        data?.indicatorType === indicatorTypeEnum.IndicatorTableValue ? tableValueColumnOptions : setColumnOptions
      "
      [noData]="!data"
      [isVisible]="isVisibleInReport"
      [hasVisibilityOption]="hasVisibilityOption"
      [style.top.px]="toolbarTop"
      (onDelete)="handleOnDelete()"
      (onUpdateVisibility)="handleOnUpdateVisibility($event)"></esg-indicator-content-toolbar>
    } @if (data && data.indicatorType && states.indicatorValues.length) { @if (data.indicatorType ===
    indicatorTypeEnum.IndicatorSet) {
    <esg-indicator-content-set
      [indicatorSet]="states.indicatorValues"
      [indicatorPeriodLabels]="states.indicatorPeriodLabels"
      [visibleColumns]="data.visibleColumns"
      [indicatorOptions]="states.indicatorOptions"
      [active]="active"
      [indicatorIds]="data.indicatorIds"
      [isVisibleInReport]="isVisibleInReport"
      (onAddIndicator)="handleOnAddIndicatorToSet($event)"
      (onRemoveIndicator)="handleOnRemoveIndicatorFromSet($event)"
      (onMoveIndicator)="handleOnMoveIndicatorInSet($event)"></esg-indicator-content-set>
    } @else if(data.indicatorType === indicatorTypeEnum.IndicatorTableValue) {
    <esg-indicator-table-value-preview
      [tableValue]="states.indicatorValues[0]"
      [indicatorPeriodLabels]="states.indicatorPeriodLabels"
      [visibleColumns]="data.visibleColumns"
      [class.active]="active"></esg-indicator-table-value-preview>
    } } @else { @if (active) {
    <div class="empty-active-container">
      <esg-select
        [customDropdown]="true"
        [groupedOptions]="states.indicatorOptions"
        [isCollapsed]="indicatorSelectCollapsed"
        (onChange)="handleOnAddIndicator($event)"
        (onToggleCollapse)="handleOnIndicatorSelectCollapse($event)">
        <esg-add-button label="Select indicator" (onClick)="handleOnIndicatorSelectClick($event)"></esg-add-button>
      </esg-select>
    </div>
    } @if (!active) {
    <esg-empty-content
      (onClick)="handleComponentClick()"
      infoText="No indicator added."
      addText=" Click to add indicator..."></esg-empty-content>
    } }
  </div>
  }
</div>
