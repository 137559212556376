<esg-data-import-from-excel
  *ngIf="importFromExcelDialog"
  (importData)="importDataFromExcel($event)"
  (onClose)="importDataFromExcelClose()"></esg-data-import-from-excel>
<div *ngIf="mode === Modes.Edit" class="wrapper" [class.collapsed]="reorderMode" [class.active]="active">
  <esg-content-topbar
    *ngIf="reorderMode"
    [id]="contentId"
    cdkDrag
    [text]="getHeadersAsString || ''"
    (onDelete)="onDelete.emit(contentId)">
    <span cdkDragHandle handle class="topbar__handle">
      <esg-drag-icon></esg-drag-icon>
    </span>
    <esg-import-from-excel-2-icon typeIcon></esg-import-from-excel-2-icon>
  </esg-content-topbar>
  <div
    *ngIf="!reorderMode"
    class="container"
    [style.position]="'relative'"
    esg-on-click-outside
    (onClickOutside)="active && handleOutsideClick()"
    (click)="handleComponentClick($event)"
    [ignoreIfSelected]="true">
    <div *ngIf="active" class="toolbar-container" [style.top.px]="toolbarTop">
      <div class="toolbar">
        <button (click)="handleImportFromExcel()">Import Excel file</button>
        <div *ngIf="hasVisibilityOption" class="visible-toggle">
          <esg-toggle-switch [checked]="isVisibleInReport" (onCheck)="handleUpdateVisibility()"></esg-toggle-switch>
          Visible
        </div>
        <button (click)="onDelete.emit(contentId)"><esg-delete-icon></esg-delete-icon></button>
      </div>
    </div>
    <div
      class="content-container"
      [class.edit-mode]="active"
      [class.empty]="showEmptyContent"
      [class.not-visible]="!isVisibleInReport">
      <div class="content">
        <esg-table-data
          *ngIf="tableConfig && rows"
          [editMode]="active"
          [config]="tableConfig"
          [rows]="rows"></esg-table-data>

        <esg-empty-content
          *ngIf="showEmptyContent"
          class="empty-table-data"
          [class.active]="active"
          (onClick)="handleComponentClick($event)"
          infoText="No data added."
          addText=" Click to import from Excel..."></esg-empty-content>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mode === Modes.Read" class="wrapper">
  <div class="content-container">
    <esg-table-data
      *ngIf="tableConfig && rows"
      [editMode]="false"
      [config]="tableConfig"
      [rows]="rows"></esg-table-data>
  </div>
</div>
