import { Injectable } from '@angular/core';
import _ from 'lodash';
import {
  CreateIndicatorCommandParams,
  IndicatorCategory,
  SectorType,
  SectorVm,
  UserRole,
} from 'src/api-client/report-api.generated';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';
import { IndicatorsPageStateService } from '../indicators-page-state.service';
import { ObjectArray, toHashTableBy } from 'src/app/shared/utils/object-array';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import { Subscription } from 'rxjs';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IOption } from 'src/app/static-data/options';
import { PaginationUi } from 'src/app/shared/components/filter-sort-bar/filter-pagination-bar.component';
import { FormControl } from '@angular/forms';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';

export interface IndicatorDefinitionUi {
  id: string;
  name: string;
  description: string;
  category: IndicatorCategory;
  frameworkStandards: string;
  sectors: SectorType[];
  isAdded: boolean;
}

@Injectable()
export class IndicatorsLibraryStateService {
  private userInfoSub!: Subscription;

  indicatorDefinitions: IndicatorDefinitionUi[] = [];
  loading: boolean = false;
  sectorTypes: ObjectArray<SectorVm> = {};
  searchValue: string = "";
  categoryOptions: IOption[] = Object.values(IndicatorCategory).map(c => ({ value: c, label: c }));
  searchTimeout: any;
  
  CategoryColors: { [key in IndicatorCategory]: string } = {
    [IndicatorCategory.Environment]: '#23D468',
    [IndicatorCategory.Social]: '#BD15F8',
    [IndicatorCategory.Governance]: '#03B3FF',
    [IndicatorCategory.Other]: '#183642',
  };

  CategoryLabels: { [key in IndicatorCategory]: string } = {
    [IndicatorCategory.Environment]: 'Environmental',
    [IndicatorCategory.Social]: 'Social',
    [IndicatorCategory.Governance]: 'Governance',
    [IndicatorCategory.Other]: 'Other',
  };

  hasAddPermission: boolean = false;  pageSizeOptions: IOption[] = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '250', label: '250' },
  ];

  currentPagination: PaginationUi = {
    currentPage: 1,
    totalPages: 1,
    pageSize: 50,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  };

  filteredCategory: FormControl<IOption[]> = new FormControl([], { nonNullable: true });
  
  constructor(
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private IndicatorApiService: IndicatorApiService,
    private sectorApiService: SectorApiService,
    private menuState: IndicatorsPageStateService,
    private appInfo: AppInfoService
  ) {
    this.userInfoSub = this.appInfo.userInfo$.subscribe(userInfo => {
      this.hasAddPermission = userInfo?.role === UserRole.Admin || userInfo?.role === UserRole.SuperAdmin;
    });
    this.init();
  }

  async init() {
    this.loading = true;
    await Promise.all([this.getSectors(), this.getIndicators()]);
    this.loading = false;
  }

  async getIndicators() {
    const response = await this.indicatorDefinitionsApiService.getAll(
      true,
      this.searchValue,
      Object.values(IndicatorCategory).filter(type => this.filteredCategory.value.find(opt => type === opt.value)),
      undefined,
      undefined,
      this.currentPagination.currentPage,
      this.currentPagination.pageSize
    );

    this.currentPagination = {
      currentPage: response.result.currentPage,
      totalPages: response.result.totalPages,
      pageSize: response.result.pageSize,
      totalCount: response.result.totalCount,
      hasPrevious: response.result.hasPrevious,
      hasNext: response.result.hasNext,
    };

    this.indicatorDefinitions = _.orderBy(response.result.items, 'name').map(indicator => ({
      id: indicator.id,
      name: indicator.name,
      description: indicator.description,
      category: indicator.category,
      frameworkStandards: indicator.frameworkStandards,
      sectors: indicator.sectors,
      isAdded: indicator.isAssignedToIndicator,
    }));
    this.menuState.setLibraryIndicatorCount(this.indicatorDefinitions.length);
  }

  filterMenuProvider = (): IMenuItem[] => [
    { id: 'clear', label: 'Clear all filters', onClick: () => this.clearFilters() },
  ];

  clearFilters() {
    this.filteredCategory.setValue([]);
    this.handleFilterChange();
  }

  handleFilterChange() {
    this.currentPagination = { ...this.currentPagination, currentPage: 1, hasNext: false, hasPrevious: false };
    this.getIndicators();
  }

  handlePaginationChange(newPaginationData: PaginationUi) {
    this.currentPagination = newPaginationData;
    this.getIndicators();
  }

  onSearchValueChange(newSearchValue: string) {
    this.searchValue = newSearchValue;

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  
    this.searchTimeout = setTimeout(() => {
      this.handleFilterChange();
    }, 500);
  }

  async getSectors() {
    const response = await this.sectorApiService.getAll();
    this.sectorTypes = toHashTableBy(response.result, 'type');
  }

  async addIndicatorToOrganization(indicatorId: string) {
    if (!this.hasAddPermission) {
      return;
    }

    const response = await this.IndicatorApiService.createIndicator(
      new CreateIndicatorCommandParams({ indicatorDefinitionId: indicatorId })
    );
    if (response.success) {
      const indicatorIndex = this.indicatorDefinitions.findIndex(indicator => indicator.id === indicatorId);
      if (indicatorIndex !== -1) {
        this.indicatorDefinitions[indicatorIndex].isAdded = true;
        if (this.menuState.ourIndicatorsCount !== undefined) {
          this.menuState.setOurIndicatorsCount(this.menuState.ourIndicatorsCount + 1);
        }
        const category = this.indicatorDefinitions[indicatorIndex].category;
        if (this.menuState.categoryCounts[category] !== undefined) {
          this.menuState.categoryCounts[category] = this.menuState.categoryCounts[category]! + 1;
          this.menuState.clearRecentlyAddedFlag(category);
          this.menuState.setRecentlyAddedFlag(category);
        }
      }
    }
  }

  getSectorsStringList(sectors: SectorType[]): string {
    if (sectors.length === 0) {
      return 'All';
    } else return sectors.map(sector => this.sectorTypes[sector]?.name || sector).join('\n');
  }

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
  }
}
