<div *ngIf="mode === Modes.Edit" class="wrapper" [class.collapsed]="reorderMode">
  <esg-content-topbar
    *ngIf="reorderMode"
    [id]="contentId"
    cdkDrag
    [imgSrc]="states.uploadedPhoto?.url || ''"
    [text]="fc.altTag.value || ''"
    (onDelete)="onDelete.emit(contentId)">
    <span cdkDragHandle handle class="topbar__handle">
      <esg-drag-icon></esg-drag-icon>
    </span>
    <esg-image-icon typeIcon></esg-image-icon>
  </esg-content-topbar>
  <div
    *ngIf="!reorderMode"
    class="container"
    esg-on-click-outside
    (onClickOutside)="handleOutsideClick()"
    [class.image-focus]="active && !editing"
    [class.empty]="!states.uploadedPhoto?.url && !editing"
    [class.not-visible]="!isVisibleInReport">
    <esg-picture-toolbar
      *ngIf="active && states.uploadedPhoto?.url"
      [altTagControl]="this.fc.altTag"
      [edit]="editing"
      [isVisible]="isVisibleInReport"
      [hasVisibilityOption]="hasVisibilityOption"
      [style.top.px]="adjustedToolbarTop"
      (onReplace)="handleOnEdit()"
      (onCancel)="handleOnEditCancel()"
      (onTagUpdate)="handleOnAltTagChange($event)"
      (onDelete)="onDelete.emit(contentId)"
      (onUpdateVisibility)="handleOnUpdateVisibility($event)"></esg-picture-toolbar>
    <esg-upload-picture
      *ngIf="active && editing"
      accepted="image/jpeg,image/jpg,image/png,image/svg+xml"
      [uploadedPhotos]="states.uploadedPhoto ? [states.uploadedPhoto] : []"
      [texts]="componentTexts"
      variant="component"
      (onSelectFile)="handleUploadCoverPhoto($event)"
      (onClearUploadedPhoto)="handleClearPhoto()"
      [dropZoneStyle]="dropZoneStyle"
      [altTagControl]="fc.altTag"></esg-upload-picture>
    <ng-container *ngIf="!editing">
      <div *ngIf="states.uploadedPhoto?.url" class="image" (click)="handleImageClick()">
        <img [src]="states.uploadedPhoto?.url" />
      </div>
      <esg-empty-content
        *ngIf="!states.uploadedPhoto?.url"
        (onClick)="handleOnEdit()"
        infoText="No image added."
        addText="Click to add image..."></esg-empty-content>
    </ng-container>
  </div>
</div>

<div *ngIf="mode === Modes.Read" class="wrapper">
  <div class="container">
    <div *ngIf="states.uploadedPhoto?.url" class="image">
      <img [src]="states.uploadedPhoto?.url" [alt]="this.data?.altText" />
    </div>
  </div>
</div>
