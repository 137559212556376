<div class="content">
  <div class="wrapper" [class.not-desktop]="!reportState.isDesktop">
    <div class="title">Key Figures {{ isCustomPeriod ? '' : getFigureTimeLabel(0) }}</div>
    <div class="table">
      <div class="box header"></div>
      <div class="box header"></div>
      <div class="box header second-col">UNIT</div>
      <div class="box header alignRight">{{ isCustomPeriod ? 'TARGET' : 'TARGET ' + getFigureTimeLabel(1) }}</div>
      <div class="box header alignRight">{{ isCustomPeriod ? 'CURRENT' : getFigureTimeLabel(0) }}</div>
      <div class="box header alignRight">{{ isCustomPeriod ? 'PREVIOUS' : getFigureTimeLabel(-1) }}</div>
      <div class="box header last-col">REFERENCE</div>

      <ng-container *ngIf="isCustomPeriod">
        <div class="box cell"></div>
        <div class="box cell"></div>
        <div class="box cell second-col"></div>
        <div class="box italicCell alignRight">{{ getFigureTimeLabel(1) }}</div>
        <div class="box italicCell alignRight">{{ getFigureTimeLabel(0) }}</div>
        <div class="box italicCell alignRight">{{ getFigureTimeLabel(-1) }}</div>
        <div class="box cell last-col"></div>
      </ng-container>

      <ng-container *ngFor="let keyFigureTable of state.keyFiguresList">
        <ng-container *ngIf="keyFigureTable.mainTopic">
          <esg-dynamic-table-header-cell
            [content]="keyFigureTable.mainTopic"
            [bgColor]="reportState.tableHeadColor"></esg-dynamic-table-header-cell>
          <esg-dynamic-table-header-cell [bgColor]="reportState.tableHeadColor"></esg-dynamic-table-header-cell>
          <esg-dynamic-table-header-cell [bgColor]="reportState.tableHeadColor"></esg-dynamic-table-header-cell>
          <esg-dynamic-table-header-cell [bgColor]="reportState.tableHeadColor"></esg-dynamic-table-header-cell>
          <esg-dynamic-table-header-cell [bgColor]="reportState.tableHeadColor"></esg-dynamic-table-header-cell>
          <esg-dynamic-table-header-cell [bgColor]="reportState.tableHeadColor"></esg-dynamic-table-header-cell>
          <esg-dynamic-table-header-cell [bgColor]="reportState.tableHeadColor"></esg-dynamic-table-header-cell>

          <ng-container *ngFor="let keyFigure of keyFigureTable.keyFigures">
            <div class="box cell first-col">{{ keyFigure.indicator }}</div>
            <div class="nested">
              <ng-container *ngFor="let value of keyFigure.rows">
                <div class="box cell first-col">{{ value.label }}</div>
                <div class="box italicCell second-col">{{ value.unit | formatUnitWithUnicode }}</div>
                <div class="box cell alignRight">{{ getFormattedNumber(value.target || '-', 0, 2) }}</div>
                <div class="box boldCell alignRight">{{ getFormattedNumber(value.current || '-', 0, 2) }}</div>
                <div class="box cell alignRight">{{ getFormattedNumber(value.previous || '-', 0, 2) }}</div>
              </ng-container>
            </div>
            <div class="box italicCell last-col">{{ keyFigure.references }}</div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
