import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'esg-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit {
  @Input() fc?: FormControl<boolean | null>;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onCheck = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleFieldClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.disabled) {
      this.onCheck.emit();
    }
  }
}
