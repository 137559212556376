import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import {
  CreateVehicleConsumptionDataRecordCommandParams,
  DataRecordType,
  InputType,
  UpdateVehicleConsumptionDataRecordCommandParams,
  VehicleConsumptionDataRecord,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';

interface VehicleCompustionRecordForm extends RecordForm {
  diesel_l: FormControl<string>;
  petrol_l: FormControl<string>;
  hydrogen_kg: FormControl<string>;
  liquidPetroleumGas: FormControl<string>;
  liquidPetroleumGas_unit: FormControl<IOption>;
  naturalGas: FormControl<string>;
  naturalGas_Unit: FormControl<IOption>;
}

@Component({
  selector: 'esg-vehicle-combustion-form-table',
  templateUrl: './vehicle-combustion-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: VehicleCombustionFormTableComponent }],
})
export class VehicleCombustionFormTableComponent extends RecordFormTableComponent {
  headerCells = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'diesel', label: 'Diesel', description: 'Litres', number: true },
    { columnId: 'petrolGasoline', label: 'Petrol/Gasoline', description: 'Litres', number: true },
    { columnId: 'hydrogen', label: 'HYDROGEN', description: 'Litres', number: true },
    { columnId: 'naturalGas', label: 'NG', description: 'mt or m3', number: true, possibleToHide: true },
    { columnId: 'liquidPetroleumGas', label: 'LPG', description: 'mt or Litres', number: true, possibleToHide: true },
  ];

  recordFc?: VehicleCompustionRecordForm = undefined;
  recordFormgroup?: FormGroup<VehicleCompustionRecordForm> = undefined;

  liquidPetroleumGasUnits: IOption[] = [
    { value: 'mt', label: 'mt', optionLabel: 'Metric Tons (mt)' },
    { value: 'l', label: 'l', optionLabel: 'Litres (l)' },
  ];

  naturalGasUnits: IOption[] = [
    { value: 'mt', label: 'mt', optionLabel: 'Metric Tons (mt)' },
    { value: 'm3', label: 'm³', optionLabel: 'Cubic Metres (m³)' }
  ];

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as VehicleConsumptionDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'diesel',
          value: record.diesel_l?.toString() || '',
          number: true,
        },
        {
          columnId: 'petrolGasoline',
          value: record.petrol_l?.toString() || '',
          number: true,
        },
        {
          columnId: 'hydrogen',
          value: record.hydrogen_kg?.toString() || '',
          number: true,
        },
        {
          columnId: 'naturalGas',
          value: record.naturalGas?.toString() || '',
          number: true,
          unit: record.naturalGas ? this.naturalGasUnits.find(c => c.value === record.naturalGas_Unit)?.label || record.naturalGas_Unit : '',
        },
        {
          columnId: 'liquidPetroleumGas',
          value: record.liquidPetroleumGas?.toString() || '',
          number: true,
          unit: record.liquidPetroleumGas ? this.liquidPetroleumGasUnits.find(c => c.value === record.liquidPetroleumGas_Unit)?.label || record.liquidPetroleumGas_Unit : '',
        },
      ],
    }));
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      diesel_l: new FormControl<string>('', {
        nonNullable: true,
      }),
      petrol_l: new FormControl<string>('', {
        nonNullable: true,
      }),
      hydrogen_kg: new FormControl<string>('', {
        nonNullable: true,
      }),
      liquidPetroleumGas: new FormControl<string>('', {
        nonNullable: true,
      }),
      liquidPetroleumGas_unit: new FormControl<IOption>(this.liquidPetroleumGasUnits[0], {
        nonNullable: true,
      }),
      naturalGas: new FormControl<string>('', {
        nonNullable: true,
      }),
      naturalGas_Unit: new FormControl<IOption>(this.naturalGasUnits[0], {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const vehicleRecord = this.dataRecords.find(record => record.id === id) as VehicleConsumptionDataRecord;
    if (vehicleRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(vehicleRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(vehicleRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === vehicleRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        diesel_l: new FormControl<string>(vehicleRecord.diesel_l?.toString() || '', {
          nonNullable: true,
        }),
        petrol_l: new FormControl<string>(vehicleRecord.petrol_l?.toString() || '', {
          nonNullable: true,
        }),
        hydrogen_kg: new FormControl<string>(vehicleRecord.hydrogen_kg?.toString() || '', {
          nonNullable: true,
        }),
        liquidPetroleumGas: new FormControl<string>(vehicleRecord.liquidPetroleumGas?.toString() || '', {
          nonNullable: true,
        }),
        liquidPetroleumGas_unit: new FormControl<IOption>(
          this.liquidPetroleumGasUnits.find(opt => opt.value === vehicleRecord.liquidPetroleumGas_Unit) || this.liquidPetroleumGasUnits[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        naturalGas: new FormControl<string>(vehicleRecord.naturalGas?.toString() || '', {
          nonNullable: true,
        }),
        naturalGas_Unit: new FormControl<IOption>(
          this.naturalGasUnits.find(opt => opt.value === vehicleRecord.naturalGas_Unit) || this.naturalGasUnits[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateVehicleConsumptionDataRecordCommandParams
    | UpdateVehicleConsumptionDataRecordCommandParams
    | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const vehicleCompustion = {
        type: DataRecordType.VehicleConsumption,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        diesel_l: formattedStringToNumber(this.recordFc.diesel_l.value || '') || 0,
        petrol_l: formattedStringToNumber(this.recordFc.petrol_l.value || '') || 0,
        hydrogen_kg: formattedStringToNumber(this.recordFc.hydrogen_kg.value || '') || 0,
        liquidPetroleumGas: formattedStringToNumber(this.recordFc.liquidPetroleumGas.value || '')|| 0,
        liquidPetroleumGas_Unit: this.recordFc.liquidPetroleumGas_unit.value.value,
        naturalGas: formattedStringToNumber(this.recordFc.naturalGas.value || '')|| 0,
        naturalGas_Unit: this.recordFc.naturalGas_Unit.value.value,
      };
      if (this.editRecordId) {
        return new UpdateVehicleConsumptionDataRecordCommandParams({ id: this.editRecordId, ...vehicleCompustion });
      } else {
        return new CreateVehicleConsumptionDataRecordCommandParams(vehicleCompustion);
      }
    }
    return undefined;
  }
}
