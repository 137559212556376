import { Injectable } from '@angular/core';
import { numberToFormattedNumberString } from '../../../shared/utils/number-converters';
import { MainLevel } from '../../report-preview-state.service';

interface KeyFigureRowsUi {
  indicator?: string;
  references?: string;
  rows: KeyFigureRowUi[];
}

interface KeyFigureRowUi {
  label?: string | undefined;
  unit?: string | undefined;
  type?: KeyFigureInputType;
  previous?: string | undefined;
  current: string | undefined;
  target?: string | undefined;
}

enum KeyFigureInputType {
  Text = 'Text',
  Numeric = 'Numeric',
}

export interface KeyFiguresTable {
  mainTopic?: string;
  keyFigures?: KeyFigureRowsUi[];
}

@Injectable()
export class KeyFiguresPreviewStateService {
  keyFiguresList: KeyFiguresTable[] = [];

  constructor() { }

  setKeyFigures(mainLevels: (MainLevel | undefined)[]) {
    this.keyFiguresList = mainLevels?.map(mainTopic => {
      let topicItems = mainTopic?.topics?.flatMap(x => x.topicItems || []);
      let keyFigures: KeyFigureRowsUi[] = [];
      topicItems?.map(item => {
        let indicatorValues = item.dataValues?.reduce((acc: KeyFigureRowUi[], figure) => {
          if (figure.metadata.type === 'Numeric' && figure.metadata.isVisibleInReport) {
            const matchingComponents = item.components?.filter(component => {
              return component.id && figure.usedInComponentIds?.includes(component.id)
            });

            const visibleColumns = matchingComponents
            ? Array.from(
                new Set(
                  matchingComponents.flatMap(component => {
                    const content = JSON.parse(component.content);
                    return content.visibleColumns || [];
                  })
                )
              )
            : [];

            const hasTargetColumn = visibleColumns.includes('TargetValue');
            const hasCurrentColumn = visibleColumns.includes('CurrentValue');
            const hasPreviousColumn = visibleColumns.includes('PreviousValue');
            acc.push({
              label: figure.metadata.label,
              unit: figure.currentValue.unitDetails || figure.metadata.unit,
              target:
                hasTargetColumn
                  ? (figure.targetValue.value || figure.targetValue.value === 0
                    ? numberToFormattedNumberString(Number(figure.targetValue.value))
                    : '-')
                  : '-',
              current:
                hasCurrentColumn
                  ? (figure.currentValue.value || figure.currentValue.value === 0
                    ? numberToFormattedNumberString(Number(figure.currentValue.value))
                    : '-')
                  : '-',
              previous:
                hasPreviousColumn
                  ? (figure.previousValue.value || figure.previousValue.value === 0
                    ? numberToFormattedNumberString(Number(figure.previousValue.value))
                    : '-')
                  : '-',
            });
          }
          return acc;
        }, []);
        indicatorValues?.length &&
          keyFigures.push({ indicator: item.name, references: item.referenceStandards, rows: indicatorValues });
      });
      return keyFigures && keyFigures.length > 0 ? { mainTopic: mainTopic?.name, keyFigures: keyFigures } : {};
    });
  }
}
