<quill-editor
  #editor
  format="html"
  [formats]="formats"
  [formControl]="fc"
  [placeholder]="placeholder"
  [readOnly]="readOnly"
  [sanitize]="true"
  (onEditorCreated)="setFocus($event)"
  (focusout)="onFocusOut.emit()"
  (onBlur)="onBlur.emit()"
  (onFocus)="onFocus.emit()"
  (click)="!chatPrompt && hasParagraphStyles && paragraphValueSync()"
  (keyup)="!chatPrompt && hasParagraphStyles && paragraphValueSync()"
  [styles]="editorStyles"
  [classes]="classes">
  <div
    esg-text-editor-toolbar
    quill-editor-toolbar
    (onAddLink)="handleAddLink()"
    (onAddDivider)="handleAddDivider()"
    (onDelete)="handleOnDelete()"
    (onSetHeader)="handleSetHeader($event)"
    (onGenerateText)="handleOnSubmitPrompt()"
    (onBotClick)="handleOnChatBotClick()"
    (onBotCancel)="handleOnChatBotCancel()"
    (onUpdateVisibility)="onUpdateVisibility.emit($event)"
    [ngStyle]="toolbarStyles"
    [hasParagraphStyles]="hasParagraphStyles"
    [hasDeleteOption]="onDelete.observed"
    [hasChatBotOption]="hasChatBotOption"
    [hasVisibilityOption]="hasVisibilityOption"
    [isVisible]="isVisible"
    [paragraphOption]="paragraphOption"
    [paragraphOptions]="paragraphOptions"
    [chatPrompt]="chatPrompt"
    [botDisabled]="fc.value ? true : false"></div>
</quill-editor>
