import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'esg-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  host: {
    '(mouseenter)': 'setHideScroll(false)',
    '(mouseleave)': 'setHideScroll(true)',
  },
})
export class SideMenuComponent {
  @Input() showBackButton: boolean = false;
  @Input() goBackUrl: string = '/';
  @Input() backButtonLabel: string = 'Back to home';
  hideScroll: boolean = true;

  constructor(private router: Router) {}

  setHideScroll(hideScroll: boolean) {
    this.hideScroll = hideScroll;
  }

  navigateBack() {
    this.router.navigate([this.goBackUrl]);
  }
}
