import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CreateComponentCommandParams,
  UpdateComponentCommandParams,
  MoveComponentCommandParams,
  DeleteComponentCommandParams,
  ComponentType,
  IndicatorCategory,
  IndicatorValueType,
  TimePeriod,
  IndicatorDataValueType,
} from 'src/api-client/report-api.generated';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { ContentDataTypes, ContentStatesTypes, ContentTypes } from 'src/app/content/content-configuration';
import { DynamicContent, DynamicComponentFactory } from 'src/app/content/dynamic-content';
import { IPictureContentData } from 'src/app/content/content-item/picture/picture.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { IMovedContent } from 'src/app/content/dynamic-content-data';
import { ChatAIApiService } from 'src/app/api-client/report-api/chat-ai-service';
import { lastValueFrom } from 'rxjs';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import _ from 'lodash';
import { IGroupedOptions } from 'src/app/static-data/options';
import {
  IIndicatorContentData,
  IIndicatorContentStates,
  IndicatorContentType,
  IndicatorContentValuesUi,
  IndicatorPeriodLabelsUi,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { TopicItemContentUi } from '../topic-item-details-state.service';

@Injectable()
export class ComponentsStateService {
  constructor(
    private topicItemApiService: TopicItemApiService,
    private appApiService: ReportAppApiService,
    private indicatorApiService: IndicatorApiService,
    private domSanitizer: DomSanitizer,
    private chatAIService: ChatAIApiService
  ) {}

  reportId?: string;
  topicItemId?: string;
  topicItem?: TopicItemContentUi;
  componentsList: DynamicContent[] = [];

  defaultCompTextTitle = 'Untitled paragraph';
  defaultCompImageTitle = 'Untitled image';
  defaultCompImportFromExcelTitle = 'Untitled import from excel';

  indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };
  indicatorOptions: IGroupedOptions[] = [];
  indicatorSingleValueOptions: IGroupedOptions[] = [];

  loading: boolean = false;
  fade: boolean = false;

  async getTopicItemComponents(
    reportId: string,
    topicItemId: string,
    topicItem?: TopicItemContentUi,
    timePeriod?: TimePeriod
  ) {
    this.reportId = reportId;
    this.topicItemId = topicItemId;
    this.indicatorPeriodLabels = timePeriod
      ? {
          targetLabel: 'Target ' + getTimeLabelFromPeriod(timePeriod, 1),
          currentLabel: getTimeLabelFromPeriod(timePeriod, 0),
          previousLabel: getTimeLabelFromPeriod(timePeriod, -1),
        }
      : {
          targetLabel: 'Target',
          currentLabel: 'Current',
          previousLabel: 'Previous',
        };
    this.topicItem = topicItem;
    this.loading = true;
    await this.getAllIndicatorOptions();
    this.componentsList = [];
    this.topicItemApiService.getTopicItemComponents(topicItemId, reportId).subscribe(result => {
      result.map(c => {
        const dynamicComponentFactory = DynamicComponentFactory({
          uploadPhoto: this.handleUploadPhoto,
          getUploadedPhoto: this.getUploadPhoto,
          generateText: this.handleGetBotAnswer,
          addIndicator: this.handleAddIndicatorToSet,
          deleteIndicator: this.handleRemoveIndicatorFromSet,
        });

        let dynamicComponent;
        if (c.type === ComponentType.Indicator) {
          const content = c.content ? JSON.parse(c.content) : undefined;
          const indicatorIds = content?.indicatorIds || [];
          const indicatorValues = this.getOrderedIndicatorValues(this.topicItem?.dataValues || [], indicatorIds);
          dynamicComponent = dynamicComponentFactory[c.type](
            c.id,
            c.isVisibleInReport,
            this.indicatorPeriodLabels,
            c.content ? this.getUnusedIndicatorOptions(indicatorIds) : this.indicatorOptions,
            indicatorValues,
            c.content
          );
        } else {
          dynamicComponent = dynamicComponentFactory[c.type](c.id, c.isVisibleInReport, c.content);
        }

        this.componentsList.push(dynamicComponent);
      });
      this.loading = false;
    });
  }

  getUnusedIndicatorOptions(indicatorIds: string[]) {
    return this.indicatorSingleValueOptions.map(group => {
      return {
        label: group.label,
        options: group.options.filter(option => !indicatorIds.includes(option.value)),
      };
    });
  }

  getOrderedIndicatorValues(
    indicatorValues: IndicatorContentValuesUi[],
    indicatorIds: string[]
  ): IndicatorContentValuesUi[] {
    return indicatorIds.map(id => indicatorValues.find(value => value.indicatorId === id)!).filter(Boolean);
  }

  async getAllIndicatorOptions() {
    const categories = [
      IndicatorCategory.Environment,
      IndicatorCategory.Social,
      IndicatorCategory.Governance,
      IndicatorCategory.Other,
    ];
    const availableIndicatorsResponse = await this.indicatorApiService.getAllIndicators(
      undefined,
      undefined,
      undefined,
      undefined
    );

    const singleValueIndicators = availableIndicatorsResponse.result.items.filter(
      x => x.valueType === IndicatorValueType.Numeric
    );

    const groupedIndicators = categories.map(category => {
      return {
        label: category,
        options: _.orderBy(
          availableIndicatorsResponse.result.items.filter(x => x.category === category),
          i => i.name
        ).map(x => {
          return { value: x.id, label: x.name, category: x.category };
        }),
      };
    });

    const singleGroupedIndicators = categories.map(category => {
      return {
        label: category,
        options: _.orderBy(
          singleValueIndicators.filter(x => x.category === category),
          i => i.name
        ).map(x => {
          return { value: x.id, label: x.name, category: x.category };
        }),
      };
    });

    this.indicatorOptions = groupedIndicators;
    this.indicatorSingleValueOptions = singleGroupedIndicators;
  }

  handleAddIndicatorToSet = (contentId: string) => async (indicatorId: string) => {
    const component = this.componentsList.find(c => c.contentId === contentId);
    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exists`);
    }
    if (component.contentType !== ContentTypes.Indicator) return;

    const response = await this.topicItemApiService.addIndicatorToComponent(
      this.topicItemId || '',
      contentId,
      indicatorId
    );
    const states = component.states as IIndicatorContentStates;
    const data = component.data as IIndicatorContentData;
    const indicator = response.result as IndicatorContentValuesUi;

    if (indicator.metadata.type === IndicatorDataValueType.Numeric) {
      if (data?.indicatorIds?.length) {
        this.onDataChangeComponent(
          contentId,
          component.isVisibleInReport,
          {
            ...component.states,
            indicatorValues: [...states.indicatorValues, indicator],
            indicatorOptions: this.getUnusedIndicatorOptions([...data.indicatorIds, indicatorId]),
          },
          {
            ...data,
            indicatorIds: [...data.indicatorIds, indicatorId],
          }
        );
      } else {
        this.onDataChangeComponent(
          contentId,
          component.isVisibleInReport,
          {
            ...component.states,
            indicatorValues: [indicator],
            indicatorOptions: this.getUnusedIndicatorOptions([indicatorId]),
          },
          {
            ...data,
            indicatorType: IndicatorContentType.IndicatorSet,
            visibleColumns: [
              VisibleValueColumn.TargetValue,
              VisibleValueColumn.CurrentValue,
              VisibleValueColumn.PreviousValue,
            ],
            indicatorIds: [indicatorId],
          }
        );
      }
    } else if (indicator.metadata.type === IndicatorDataValueType.Table) {
      this.onDataChangeComponent(
        contentId,
        component.isVisibleInReport,
        { ...component.states, indicatorValues: [indicator] },
        {
          ...data,
          indicatorType: IndicatorContentType.IndicatorTableValue,
          visibleColumns: [VisibleValueColumn.CurrentValue, VisibleValueColumn.PreviousValue],
          indicatorIds: [indicatorId],
        }
      );
    }
  };

  handleRemoveIndicatorFromSet = (contentId: string) => async (indicatorId: string) => {
    const component = this.componentsList.find(c => c.contentId === contentId);
    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exist`);
    }
    if (component.contentType !== ContentTypes.Indicator) return;

    const response = await this.topicItemApiService.removeIndicatorFromComponent(
      this.topicItemId || '',
      contentId,
      indicatorId
    );

    if (response.success) {
      const states = component.states as IIndicatorContentStates;
      const indicatorRows = states.indicatorValues || [];
      const newIndicatorRows = indicatorRows.filter(i => i.indicatorId !== indicatorId);
      this.onDataChangeComponent(
        contentId,
        component.isVisibleInReport,
        {
          ...component.states,
          indicatorValues: newIndicatorRows,
        },
        component.data
      );
    }
  };

  handleOnAddComponent(type: ContentTypes) {
    switch (type) {
      case ContentTypes.Indicator:
        this.addEmptyIndicatorComponent();
        break;
      case ContentTypes.Text:
        this.addTextComponent();
        break;
      case ContentTypes.Picture:
        this.addImageComponent();
        break;
      case ContentTypes.ImportFromExcel:
        this.addImportFromExcelComponent();
        break;
      default:
        break;
    }
  }

  addEmptyIndicatorComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Indicator,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        const IndicatorComponent = DynamicComponentFactory({
          uploadPhoto: this.handleUploadPhoto,
          getUploadedPhoto: this.getUploadPhoto,
          generateText: this.handleGetBotAnswer,
          addIndicator: this.handleAddIndicatorToSet,
          deleteIndicator: this.handleRemoveIndicatorFromSet,
        })[ContentTypes.Indicator](
          result.result?.id || '',
          true,
          this.indicatorPeriodLabels,
          this.indicatorOptions,
          [],
          JSON.stringify({
            visibleColumns: [
              VisibleValueColumn.PreviousValue,
              VisibleValueColumn.CurrentValue,
              VisibleValueColumn.TargetValue,
            ],
          })
        );
        this.componentsList = [...this.componentsList, IndicatorComponent];
      });
  }

  addTextComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Text,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        this.componentsList = [
          ...this.componentsList,
          new DynamicContent(
            result.result?.id || '',
            ContentTypes.Text,
            true,
            {
              generateText: this.handleGetBotAnswer,
            },
            {},
            undefined
          ),
        ];
      });
  }

  addImageComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Picture,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        this.componentsList = [
          ...this.componentsList,
          new DynamicContent(
            result.result?.id || '',
            ContentTypes.Picture,
            true,
            {
              uploadPhoto: this.handleUploadPhoto(result.result?.id || ''),
            },
            {},
            undefined
          ),
        ];
      });
  }

  addImportFromExcelComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.ImportFromExcel,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        this.componentsList = [
          ...this.componentsList,
          new DynamicContent(result.result?.id || '', ContentTypes.ImportFromExcel, true, {}, {}, undefined),
        ];
      });
  }

  moveComponent(movedContent: IMovedContent) {
    const newList = [...this.componentsList];
    const prevList = [...this.componentsList];
    moveItemInArray(newList, movedContent.prevIndex, movedContent.newIndex);
    this.componentsList = [...newList];

    this.topicItemApiService
      .moveComponent(
        new MoveComponentCommandParams({
          topicItemId: this.topicItemId || '',
          componentId: movedContent.contentId,
          prevIndex: movedContent.prevIndex,
          newIndex: movedContent.newIndex,
        })
      )
      .subscribe(
        result => {},
        error => {
          this.componentsList = [...prevList];
        }
      );
  }

  async deleteComponent(contentId: string) {
    const component = this.componentsList.find(comp => comp.contentId === contentId);
    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exist`);
    }

    if (component.contentType === ContentTypes.Indicator) {
      const response = await this.topicItemApiService.removeIndicatorComponent(this.topicItemId || '', contentId);
      if (response.success) {
        const newList = [...this.componentsList];
        newList.splice(
          this.componentsList.findIndex(comp => comp.contentId === contentId),
          1
        );
        this.componentsList = [...newList];
      }
    } else {
      this.topicItemApiService
        .removeComponent(
          new DeleteComponentCommandParams({ topicItemId: this.topicItemId || '', componentId: contentId })
        )
        .subscribe(result => {
          if (result.success) {
            const newList = [...this.componentsList];
            newList.splice(
              this.componentsList.findIndex(comp => comp.contentId === contentId),
              1
            );
            this.componentsList = [...newList];
          }
        });
    }
  }

  onDataChangeComponent = (
    contentId: string,
    isVisibleInReport: boolean,
    states: ContentStatesTypes,
    data?: ContentDataTypes
  ) => {
    const component = this.componentsList.find(c => c.contentId === contentId);

    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exists`);
    }

    var updatedComponent: DynamicContent = {
      ...component,
      isVisibleInReport: isVisibleInReport,
      states: states,
      data,
    };

    this.componentsList = this.componentsList.map(c => (c.contentId !== component.contentId ? c : updatedComponent));
    this.topicItemApiService
      .updateComponent(
        new UpdateComponentCommandParams({
          componentId: component.contentId,
          topicItemId: this.topicItemId || '',
          title: '',
          isVisibleInReport: isVisibleInReport,
          content: data ? JSON.stringify(data) : '',
        })
      )
      .subscribe(result => {
        console.log('updated successfully!');
      });
  };

  async uploadPhoto(file: File) {
    const photo$ = this.appApiService.uploadImage({
      data: file,
      fileName: file.name,
    });
    return await lastValueFrom(photo$);
  }

  getUploadPhoto = (blobName: string): IUploadedPhoto => {
    var blobUrl = this.appApiService.getBlobUrl(blobName);
    var safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    return {
      url: safeUrl,
      fileName: blobName,
    };
  };

  handleUploadPhoto = (componentId: string) => async (file?: File) => {
    const component = this.componentsList.find(c => c.contentId === componentId);

    if (!component) {
      throw Error(`Comonent with id: ${componentId} doesn't exists`);
    }

    if (file) {
      const result = await this.uploadPhoto(file);
      const uploadedPhoto = this.getUploadPhoto(result.blobName || '');

      const updatedContent: IPictureContentData = {
        altText: '',
        blobName: result.blobName || '',
      };

      this.onDataChangeComponent(
        componentId,
        component.isVisibleInReport,
        {
          ...component?.states,
          uploadedPhoto: uploadedPhoto,
        },
        updatedContent
      );
    } else {
      this.onDataChangeComponent(
        componentId,
        component.isVisibleInReport,
        {
          ...component?.states,
          uploadedPhoto: undefined,
        },
        undefined
      );
    }
  };

  handleGetBotAnswer = async (question: string): Promise<string> => {
    return (await this.chatAIService.getBotAnswer(question)).answer;
  };

  onReorder(reordering: boolean) {
    this.fade = reordering;
  }
}
