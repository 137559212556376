import { Content, TableCell } from 'pdfmake/interfaces';
import { TimePeriod, TimePeriodType } from 'src/api-client/report-api.generated';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import {
  IIndicatorContentData,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';
import { IKeyfigures } from '../types';
import { generateSubstrateData } from '../substrate-data-pdf';

export const generateIndicatorSet = function (
  content: IIndicatorContentData,
  keyFigures: IKeyfigures[],
  timePeriod?: TimePeriod
): Content {
  const references = Array.from(
    new Set(keyFigures.flatMap(value => value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ', ').split(', ')))
  ).join(', ');
  const visibleColumns = content.visibleColumns;
  const isCustomPeriod = timePeriod?.type === TimePeriodType.Custom;
  const colSpan = 2 + visibleColumns.length;

  const tableHeaders: TableCell[] = [
    { text: '', border: [false, false, false, true] },
    { text: 'UNIT', border: [false, false, false, true], style: 'indicatorHeading', margin: 4 },
  ];

  if (visibleColumns.includes(VisibleValueColumn.TargetValue)) {
    tableHeaders.push({
      text: isCustomPeriod || !timePeriod ? 'TARGET' : 'TARGET ' + getTimeLabelFromPeriod(timePeriod, 1).toUpperCase(),
      color: '#23D468',
      alignment: 'right',
      border: [false, false, false, true],
      style: 'indicatorHeading',
      margin: 4,
    });
  }

  if (visibleColumns.includes(VisibleValueColumn.CurrentValue)) {
    tableHeaders.push({
      text: isCustomPeriod || !timePeriod ? 'CURRENT' : getTimeLabelFromPeriod(timePeriod, 0).toUpperCase(),
      color: '#00B2FF',
      alignment: 'right',
      border: [false, false, false, true],
      style: 'indicatorHeading',
      margin: 4,
    });
  }

  if (visibleColumns.includes(VisibleValueColumn.PreviousValue)) {
    tableHeaders.push({
      text: isCustomPeriod || !timePeriod ? 'PREVIOUS' : getTimeLabelFromPeriod(timePeriod, -1).toUpperCase(),
      alignment: 'right',
      border: [false, false, false, true],
      style: 'indicatorHeading',
      margin: 4,
    });
  }

  const tableBody: TableCell[][] = [tableHeaders];

  if (isCustomPeriod) {
    const periodRow: TableCell[] = [
      { text: '', border: [false, false, false, true] },
      { text: '', border: [false, false, false, true] },
    ];

    if (visibleColumns.includes(VisibleValueColumn.TargetValue)) {
      periodRow.push({
        text: getTimeLabelFromPeriod(timePeriod, 1),
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorReference',
        margin: [4, 4, 4, 2],
      });
    }

    if (visibleColumns.includes(VisibleValueColumn.CurrentValue)) {
      periodRow.push({
        text: getTimeLabelFromPeriod(timePeriod, 0),
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorReference',
        margin: [4, 4, 4, 2],
      });
    }

    if (visibleColumns.includes(VisibleValueColumn.PreviousValue)) {
      periodRow.push({
        text: getTimeLabelFromPeriod(timePeriod, -1),
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorReference',
        margin: [4, 4, 4, 2],
      });
    }

    tableBody.push(periodRow);
  }

  keyFigures.map(value => {
    const dataRow: TableCell[] = [
      {
        text: value.metadata.label || '',
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
      {
        text: value.metadata.unit || '',
        italics: true,
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
    ];

    if (visibleColumns.includes(VisibleValueColumn.TargetValue)) {
      dataRow.push({
        text:
          value.targetValue.value !== undefined && value.targetValue.value !== null
            ? numberToFormattedNumberString(Number(value.targetValue.value), 0, 2)
            : '-',
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      });
    }

    if (visibleColumns.includes(VisibleValueColumn.CurrentValue)) {
      dataRow.push({
        text:
          value.currentValue.value !== undefined
            ? numberToFormattedNumberString(Number(value.currentValue.value), 0, 2)
            : ' ',
        alignment: 'right',
        bold: true,
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      });
    }

    if (visibleColumns.includes(VisibleValueColumn.PreviousValue)) {
      dataRow.push({
        text:
          value.previousValue.value !== undefined
            ? numberToFormattedNumberString(Number(value.previousValue.value), 0, 2)
            : ' ',
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      });
    }

    tableBody.push(dataRow);

    // We temporarily disable the substrate data in indicator set component due to the error in report generation
    // if (value.substrateData && value.substrateData.tBody.length) {
    //   tableBody.push([generateSubstrateData(value.substrateData, colSpan), ...Array(colSpan - 1).fill({})]);
    // }
  });

  tableBody.push([
    {
      text: references || ' ',
      colSpan: colSpan,
      margin: [4, 8, 4, 8],
      border: [false, false, false, false],
      style: 'indicatorReference',
    },
    ...Array(colSpan - 1).fill({}),
  ]);

  return {
    margin: [122, 0, 14, 0],
    table: {
      widths: [
        '*',
        'auto',
        ...(visibleColumns.includes(VisibleValueColumn.TargetValue) ? ['17%'] : []),
        ...(visibleColumns.includes(VisibleValueColumn.CurrentValue) ? ['17%'] : []),
        ...(visibleColumns.includes(VisibleValueColumn.PreviousValue) ? ['17%'] : []),
      ],
      body: tableBody,
      headerRows: 1,
      dontBreakRows: true,
    },
    layout: {
      hLineColor: '#DDDDDD',
      vLineColor: '#DDDDDD',
      hLineWidth: function () {
        return 0.5;
      },
      vLineWidth: function () {
        return 0;
      },
      paddingRight: function () {
        return 0;
      },
      paddingLeft: function () {
        return 0;
      },
      paddingTop: function () {
        return 0;
      },
      paddingBottom: function () {
        return 0;
      },
    },
  };
};
