import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Report } from 'src/api-client/report-api.generated';
import {
  CurrencyCodes,
  IndicatorCategory,
  IndicatorValueStatus,
  SectorVm,
  UserRole,
} from 'src/api-client/report-api.generated';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import { IndicatorsPageStateService } from '../indicators-page-state.service';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';
import { ObjectArray, toHashTableBy } from 'src/app/shared/utils/object-array';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';

export interface IndicatorUi {
  id: string;
  name: string;
  category: IndicatorCategory;
  lastCalculated?: Date;
  valueStatus: IndicatorValueStatus;
  errorMessage: string;
}

@Injectable()
export class IndicatorsListStateService {
  private queryParamsSub?: Subscription;
  private fragmentSub?: Subscription;
  private organizationContextSub?: Subscription;
  private userInfoSub?: Subscription;

  indicators: IndicatorUi[] = [];
  valueStatusEnum = IndicatorValueStatus;
  selectedIndicator?: IndicatorUi;
  loading: boolean = false;
  showRemoveDialog: boolean = false;
  showNotPossibleToRemoveDialog: boolean = false;
  sectorTypes: ObjectArray<SectorVm> = {};
  currentCategory?: IndicatorCategory;

  updatingSelectedIndicator: boolean = false;
  updatingAllIndicators: boolean = false;
  updatingAllIndicatorsProgress: string = '';

  organizationCurrency?: CurrencyCodes;

  hasRemovePermission: boolean = false;
  calculationUpdateIsNeeded: boolean = false;
  indicatorsLastCalculated?: Date;
  listReportUsedIndicator?: Report[] = [];

  constructor(
    private appInfoState: AppInfoService,
    private indicatorApiService: IndicatorApiService,
    private route: ActivatedRoute,
    private sectorApiService: SectorApiService,
    private organizationApiService: OrganizationApiService,
    private notificationService: NotificationService,
    private menuState: IndicatorsPageStateService
  ) {
    this.organizationContextSub = this.appInfoState.organizationContext$.subscribe(organizationId => {
      if (organizationId) {
        this.getOrganizationCurrency(organizationId);
      }
    });

    this.userInfoSub = this.appInfoState.userInfo$.subscribe(userInfo => {
      this.hasRemovePermission = userInfo?.role === UserRole.Admin || userInfo?.role === UserRole.SuperAdmin;
    });

    this.queryParamsSub = this.route.queryParams.subscribe(params => {
      this.handleQueryParamsChange(params);
    });
    this.init();
  }

  async init() {
    try {
      this.loading = true;
      await Promise.all([this.getIndicators(), this.getSectors()]);
      this.fragmentSub = this.route.fragment.subscribe(fragment => {
        this.handleFragmentChange(fragment || undefined);
      });
    } catch (error) {
      this.notificationService.showError('Unable to load indicators');
    } finally {
      this.loading = false;
    }
  }

  async getIndicators() {
    const response = await this.indicatorApiService.getAllIndicators(undefined, undefined, undefined, undefined);
    this.indicators = _.orderBy(response.result.items, 'name').map(indicator => ({
      id: indicator.id,
      name: indicator.name,
      category: indicator.category,
      lastCalculated: indicator.lastCalculated,
      valueStatus: indicator.valueStatus,
      errorMessage: indicator.valueErrors.length ? '• ' + indicator.valueErrors.join('\n• ') : '',
    }));
    this.setCalculationStatus();
    this.updateCategoryCounts();
  }

  async getSectors() {
    try {
      const response = await this.sectorApiService.getAll();
      this.sectorTypes = toHashTableBy(response.result, 'type');
    } catch (error) {
      this.notificationService.showError('Unable to load sectors');
    }
  }

  updateCategoryCounts() {
    this.menuState.setOurIndicatorsCount(this.indicators.length);
    this.menuState.categoryCounts = Object.fromEntries(Object.values(IndicatorCategory).map(category => [category, 0]));
    this.indicators.map(indicator => {
      this.menuState.categoryCounts[indicator.category] = (this.menuState.categoryCounts[indicator.category] || 0) + 1;
    });
  }

  setCalculationStatus() {
    let newestDate: Date | undefined = undefined;
    let hasOutOfDateStatus = false;

    this.indicators
      .filter(indicator => !this.currentCategory || indicator.category === this.currentCategory)
      .forEach(indicator => {
        if (indicator.lastCalculated && (!newestDate || indicator.lastCalculated > newestDate)) {
          newestDate = indicator.lastCalculated;
        }

        if (
          indicator.valueStatus === IndicatorValueStatus.OutOfDate ||
          indicator.valueStatus === IndicatorValueStatus.WithErrors
        ) {
          hasOutOfDateStatus = true;
        }
      });

    this.indicatorsLastCalculated = newestDate;
    this.calculationUpdateIsNeeded = hasOutOfDateStatus;
  }

  handleQueryParamsChange(params: Params) {
    const indicatorCategory = Object.values(IndicatorCategory).find(
      category => category.toUpperCase() === params['category']?.toUpperCase()
    );
    this.currentCategory = indicatorCategory;
    this.setCalculationStatus();
  }

  handleFragmentChange(fragment?: string) {
    if (fragment) {
      const indicator = this.indicators.find(indicator => indicator.id === fragment);
      if (indicator && (!this.currentCategory || indicator.category === this.currentCategory)) {
        this.setSelectedIndicator(indicator);
      }
    } else {
      this.setSelectedIndicator(undefined);
    }
  }

  handleInitateRemoveIndicator() {
    this.showRemoveDialog = true;
  }

  handleCloseRemoveDialog() {
    this.showRemoveDialog = false;
  }

  handleInitateNotPossibleToRemoveDialog(listReportInUsed?: Report[]) {
    this.showNotPossibleToRemoveDialog = true;
    this.listReportUsedIndicator = listReportInUsed;
  }

  handleCloseNotPossibleToRemoveDialog() {
    this.showNotPossibleToRemoveDialog = false;
  }

  async updateSelectedIndicator() {
    if (!this.selectedIndicator) {
      return;
    }

    try {
      this.updatingSelectedIndicator = true;
      this.selectedIndicator.valueStatus = this.valueStatusEnum.CalculationInProgress;
      const response = await this.indicatorApiService.calculateIndicator(this.selectedIndicator.id);
      if (response.success) {
        this.selectedIndicator.lastCalculated = response.lastCalculated;
        this.selectedIndicator.valueStatus = this.valueStatusEnum.UpToDate;
      }
    } catch (error) {
      this.notificationService.showError('Failed to update calculation');
    } finally {
      this.updatingSelectedIndicator = false;
    }
  }

  async updateAllIndicators() {
    const filteredIndicators = this.indicators.filter(
      indicator => !this.currentCategory || indicator.category === this.currentCategory
    );
    const totalIndicators = filteredIndicators.length;
    let updatedCount = 0;
    this.updatingAllIndicatorsProgress = `${updatedCount}/${totalIndicators}`;

    const requestBatchSize = 5;

    const processBatch = async (batch: IndicatorUi[]) => {
      const batchPromises = batch.map(async indicator => {
        try {
          indicator.valueStatus = this.valueStatusEnum.CalculationInProgress;
          const response = await this.indicatorApiService.calculateIndicator(indicator.id);
          if (response.success) {
            indicator.lastCalculated = response.lastCalculated;
            indicator.valueStatus = this.valueStatusEnum.UpToDate;
            updatedCount++;
            this.updatingAllIndicatorsProgress = `${updatedCount}/${totalIndicators}`;
          } else {
            throw new Error(`Failed to update indicator ${indicator.id}`);
          }
        } catch (error) {
          this.notificationService.showError(`Failed to update indicator ${indicator.name}`);
          indicator.valueStatus = this.valueStatusEnum.OutOfDate;
        }
      });
      await Promise.all(batchPromises);
    };

    this.updatingAllIndicators = true;

    try {
      for (let i = 0; i < filteredIndicators.length; i += requestBatchSize) {
        const batch = filteredIndicators.slice(i, i + requestBatchSize);
        await processBatch(batch);
      }
      if (updatedCount === filteredIndicators.length) {
        this.notificationService.showSuccess('All indicators updated successfully');
      } else {
        this.notificationService.showWarning(
          `${updatedCount}/${filteredIndicators.length} of the indicators updated successfully`
        );
      }
    } catch (error) {
      this.notificationService.showError('Failed to update some indicators');
    } finally {
      this.updatingAllIndicators = false;
      this.setCalculationStatus();
    }
  }

  removeIndicator() {
    this.showRemoveDialog = false;
    if (this.selectedIndicator) {
      this.indicatorApiService.removeIndicator(this.selectedIndicator.id).then(response => {
        if (response.success) {
          this.indicators = this.indicators.filter(indicator => indicator.id !== this.selectedIndicator?.id);
          this.updateCategoryCounts();
          this.setSelectedIndicator(undefined);
        } else {
          this.handleInitateNotPossibleToRemoveDialog(response?.reportsUsed);
        }
      });
    }
  }

  async getOrganizationCurrency(organizationId: string) {
    const response = await this.organizationApiService.getOrganizationById(organizationId);
    this.organizationCurrency = response.result.currencyCode;
  }

  setSelectedIndicator(indicator?: IndicatorUi) {
    this.selectedIndicator = indicator;
  }

  detailsMenuProvider = (): IMenuItem[] => [
    {
      id: 'delete',
      label: 'Remove Indicator',
      onClick: () => this.handleInitateRemoveIndicator(),
      disabled: !this.hasRemovePermission,
    },
  ];

  ngOnDestroy() {
    this.queryParamsSub?.unsubscribe();
    this.fragmentSub?.unsubscribe();
    this.organizationContextSub?.unsubscribe();
    this.userInfoSub?.unsubscribe();
  }
}
