<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading details"></esg-dot-flashing>

<ng-container *ngIf="!state.loading && state.indicator">
  <div class="content">
    <esg-description-and-reference
      [description]="state.indicatorDefinitonProperties?.description || ''"
      [reference]="state.indicatorDefinitonProperties?.standards || ''">
      <h2 class="title">
        {{ state.indicator.name }}
      </h2>
    </esg-description-and-reference>
  </div>
  <esg-tabs
    class="indicator-tabs"
    [activeTab]="state.activeTab"
    [tabs]="state.tabs"
    [tabIndent]="100"
    (onTabClick)="handleOnTabClick($event)"></esg-tabs>

  <div *ngIf="updatingCalculation" class="calculation-wrapper">
    <esg-dot-flashing class="loader" label="Please, wait until refreshing this indicator."></esg-dot-flashing>
  </div>
  <ng-container *ngIf="state.indicatorUnit && state.indicatorDefinitonProperties && !updatingCalculation">
    @switch (state.activeTab) { @case (state.IndicatorTabEnum.Data) {
    <esg-indicator-data
      [indicator]="state.indicator"
      [indicatorRecordDuration]="state.indicatorDefinitonProperties.recordDuration"
      [indicatorUnit]="state.indicatorUnit"
      [indicatorValueType]="state.indicatorDefinitonProperties.valueType"
      [indicatorTableDefinition]="state.indicatorTableDefinition"></esg-indicator-data>
    } @case (state.IndicatorTabEnum.Performance) {
    <esg-indicator-performance
      [indicatorId]="state.indicator.id"
      [indicatorUnit]="state.indicatorUnit"
      [indicatorNumericCalculationType]="state.indicatorDefinitonProperties.numericCalculationType"
      [indicatorTableDefinition]="state.indicatorTableDefinition"></esg-indicator-performance>
    } @case(state.IndicatorTabEnum.Properties) {
    <esg-indicator-properties
      [indicatorProperties]="state.indicatorDefinitonProperties"
      [sectorTypes]="sectorTypes"
      [compatibleRawDataSources]="state.compatibleRawDataSources"
      [compatibleIndicatorDefinitionDataSources]="
        state.compatibleIndicatorDefinitionDataSources
      "></esg-indicator-properties>
    } }
  </ng-container>
</ng-container>
