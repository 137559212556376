import { Injectable } from '@angular/core';
import {
  CreateNewIndicatorTopicItemCommandParams,
  DeleteTopicItemCommandParams,
  IndicatorCategory,
  IndicatorValueType,
  MoveTopicItemCommandParams,
  Permission,
  TimePeriod,
  TopicItemType,
  UpdateTopicItemResponsiblePersonCommandParams,
  UpdateTopicItemVisibilityCommandParams,
} from 'src/api-client/report-api.generated';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppInfoService } from 'src/app/core/app-info.service';
import { UserApiService } from 'src/app/api-client/report-api/user-api-service';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import _ from 'lodash';
import { IndicatorPeriodLabelsUi } from 'src/app/content/content-item/indicator-content/indicator-content.component';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

export interface TopicItemUi {
  id: string;
  name: string;
  visible: boolean;
  responsiblePerson?: IOption;
  collapsed: boolean;
}

@Injectable()
export class TopicContentStateService {
  isLoading = false;
  private organizationContextSub?: Subscription;

  sectionSub?: Subscription;
  fragmentSectionId?: string;

  topicTopicItems: TopicItemUi[] = [];
  reportId: string = '';
  mainLevelId: string = '';
  topicId: string = '';

  reordering: boolean = false;

  responsiblePersonOptions: IOption[] = [];

  removeTopicItemId?: string;
  removeTopicItemMessage = 'Once you delete the section\nthere is no turning back.';

  indicatorOptions: IGroupedOptions[] = [];
  indicatorSingleValueOptions: IGroupedOptions[] = [];

  indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };

  constructor(
    private topicItemApiService: TopicItemApiService,
    private appInfoService: AppInfoService,
    private userApiService: UserApiService,
    private indicatorApiService: IndicatorApiService,
    private route: ActivatedRoute
  ) {
    this.sectionSub = this.route.fragment.subscribe(topicItemId => {
      if (topicItemId) this.fragmentSectionId = topicItemId;
    });
  }

  async init(reportId: string, mainLevelId: string, topicId: string, timePeriod: TimePeriod) {
    this.isLoading = true;
    this.reportId = reportId;
    this.mainLevelId = mainLevelId;
    this.topicId = topicId;
    this.indicatorPeriodLabels = {
      targetLabel: 'Target ' + getTimeLabelFromPeriod(timePeriod, 1),
      currentLabel: getTimeLabelFromPeriod(timePeriod, 0),
      previousLabel: getTimeLabelFromPeriod(timePeriod, -1),
    };

    await this.getAllIndicatorOptions();
    this.allTopicItems(reportId, mainLevelId, topicId);

    this.organizationContextSub = this.appInfoService.organizationContext$.subscribe(organizationId => {
      if (organizationId && this.appInfoService.hasPermission(Permission.Report_User_Read)) {
        this.userApiService.getUsersByOrganizationId(organizationId).subscribe(result => {
          this.responsiblePersonOptions = [
            { value: '', label: 'Unassigned' },
            ...result.result.map(u => ({ value: u.id, label: u.name })),
          ];
        });
      }
    });
  }

  allTopicItems(reportId: string, mainLevelId: string, topicId: string) {
    this.isLoading = true;
    this.topicItemApiService.allTopicItems(reportId, mainLevelId, topicId).subscribe(result => {
      this.topicTopicItems = result.map<TopicItemUi>(u => ({
        id: u.id,
        name: u.name,
        visible: u.visible,
        responsiblePerson:
          u.responsiblePersonId && u.responsiblePersonName
            ? { value: u.responsiblePersonId, label: u.responsiblePersonName }
            : undefined,
        collapsed: true,
      }));
      this.isLoading = false;
    });
  }

  async handleOnAddHeader() {
    const response = await this.topicItemApiService.addTopicItem(
      new CreateNewIndicatorTopicItemCommandParams({
        type: TopicItemType.NewIndicator,
        reportId: this.reportId,
        mainLevelId: this.mainLevelId,
        topicId: this.topicId,
        name: '',
        indicatorIds: [],
      })
    );
    this.fragmentSectionId = response.result.id;
    this.topicTopicItems.push({
      id: response.result.id,
      name: '',
      visible: true,
      responsiblePerson: undefined,
      collapsed: false,
    });
  }

  handleOnMoveTopicItem(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.topicTopicItems, event.previousIndex, event.currentIndex);
    this.topicItemApiService
      .moveTopicItem(
        new MoveTopicItemCommandParams({
          reportId: this.reportId,
          mainLevelId: this.mainLevelId,
          topicId: this.topicId,
          topicItemId: event.item.element.nativeElement.id,
          prevIndex: event.previousIndex,
          newIndex: event.currentIndex,
        })
      )
      .subscribe({
        error: () => {
          moveItemInArray(this.topicTopicItems, event.currentIndex, event.previousIndex);
        },
      });
  }

  handleOnInitateDeleteTopicItem(topicItemId: string) {
    this.removeTopicItemId = topicItemId;
  }

  handleOnDeleteTopicItemCancel() {
    this.removeTopicItemId = undefined;
  }

  handleOnDeleteTopicItem() {
    const topicItemId = this.removeTopicItemId;
    if (topicItemId) {
      this.topicItemApiService
        .deleteTopicItem(
          new DeleteTopicItemCommandParams({
            topicItemId: topicItemId,
          })
        )
        .subscribe(result => {
          this.topicTopicItems = this.topicTopicItems.filter(t => t.id !== topicItemId);
        });
    }
    this.removeTopicItemId = undefined;
  }

  updateResponsiblePerson(topicItemId: string, user: IOption) {
    this.topicItemApiService
      .updateResponsiblePerson(
        new UpdateTopicItemResponsiblePersonCommandParams({
          topicItemId: topicItemId,
          responsiblePersonId: !!user.value ? user.value : undefined,
        })
      )
      .subscribe(result => {
        if (this.topicTopicItems && result.success) {
          this.topicTopicItems = this.topicTopicItems.map<TopicItemUi>(topicItem =>
            topicItem.id !== topicItemId
              ? topicItem
              : {
                  ...topicItem,
                  responsiblePerson: user,
                  modifiedDate: result.result?.modifiedDate,
                }
          );
        }
      });
  }

  updateVisibility(topicItemId: string, visible: boolean) {
    this.topicItemApiService
      .updateVisibility(
        new UpdateTopicItemVisibilityCommandParams({
          topicItemId: topicItemId,
          visible: visible,
        })
      )
      .subscribe(result => {
        if (this.topicTopicItems && result.success) {
          this.topicTopicItems = this.topicTopicItems.map<TopicItemUi>(topicItem =>
            topicItem.id !== topicItemId
              ? topicItem
              : {
                  ...topicItem,
                  visible: visible,
                }
          );
        }
      });
  }

  handleOnReordering() {
    this.reordering = !this.reordering;
  }

  handleOnReorderCancel() {
    this.reordering = false;
  }

  async getAllIndicatorOptions() {
    const categories = [
      IndicatorCategory.Environment,
      IndicatorCategory.Social,
      IndicatorCategory.Governance,
      IndicatorCategory.Other,
    ];
    const availableIndicatorsResponse = await this.indicatorApiService.getAllIndicators(
      undefined,
      undefined,
      undefined,
      undefined
    );

    const singleValueIndicators = availableIndicatorsResponse.result.items.filter(
      x => x.valueType === IndicatorValueType.Numeric
    );

    const groupedIndicators = categories.map(category => {
      return {
        label: category,
        options: _.orderBy(
          availableIndicatorsResponse.result.items.filter(x => x.category === category),
          i => i.name
        ).map(x => {
          return { value: x.id, label: x.name, category: x.category };
        }),
      };
    });

    const singleGroupedIndicators = categories.map(category => {
      return {
        label: category,
        options: _.orderBy(
          singleValueIndicators.filter(x => x.category === category),
          i => i.name
        ).map(x => {
          return { value: x.id, label: x.name, category: x.category };
        }),
      };
    });

    this.indicatorOptions = groupedIndicators;
    this.indicatorSingleValueOptions = singleGroupedIndicators;
  }

  ngOnDestroy() {
    this.organizationContextSub?.unsubscribe();
    this.sectionSub?.unsubscribe();
  }
}
