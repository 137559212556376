import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-search-icon',
  template: `
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.53425 12.9448C7.95327 12.9448 9.26182 12.4903 10.3324 11.7328L14.3601 15.7223C14.547 15.9074 14.7934 16 15.0568 16C15.6091 16 16 15.5792 16 15.0405C16 14.788 15.915 14.5439 15.7281 14.3672L11.726 10.3945C12.5672 9.30037 13.0685 7.94529 13.0685 6.47238C13.0685 2.91215 10.1285 0 6.53425 0C2.94849 0 0 2.90373 0 6.47238C0 10.0326 2.93999 12.9448 6.53425 12.9448ZM6.53425 11.5476C3.73022 11.5476 1.41052 9.24987 1.41052 6.47238C1.41052 3.6949 3.73022 1.39716 6.53425 1.39716C9.33829 1.39716 11.658 3.6949 11.658 6.47238C11.658 9.24987 9.33829 11.5476 6.53425 11.5476Z"
      attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class SearchIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
