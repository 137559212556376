import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import {
  CalculationType,
  CreateIndicatorDefinitionCommandParams,
  GetAllIndicatorDefinitionsQueryVm,
  IndicatorCategory,
  IndicatorDefinitionAccessibility,
  IndicatorNumericValueDefinition,
  IndicatorValueType,
  RecordDuration,
  UpdateIndicatorDefinitionCommandParams,
} from 'src/api-client/report-api.generated';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AccessibilityOptions, IOption } from 'src/app/static-data/options';
import { IndicatorDefinitionUi } from './admin-indicator-library.component';
import { Constants } from 'src/app/static-data/constants';
import { PaginationUi } from 'src/app/shared/components/filter-sort-bar/filter-pagination-bar.component';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';

interface IndicatorDefinitionForm {
  name: FormControl<string>;
  category: FormControl<IOption>;
}

@Injectable()
export class IndicatorsLibraryStateService {
  indicatorDefinitions: IndicatorDefinitionUi[] = [];
  selectedIndicatorId?: string;
  loading: boolean = false;
  searchValue: string = "";
  categoryOptions: IOption[] = Object.values(IndicatorCategory).map(c => ({ value: c, label: c }));
  accessibilityOptions: IOption[] = AccessibilityOptions;
  searchTimeout: any;

  editedIndicatorId?: string;
  indicatorForm?: IndicatorDefinitionForm;
  indicatorFormGroup?: FormGroup<IndicatorDefinitionForm>;

  pageSizeOptions: IOption[] = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '250', label: '250' },
  ];

  currentPagination: PaginationUi = {
    currentPage: 1,
    totalPages: 1,
    pageSize: 50,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  };

  filteredCategory: FormControl<IOption[]> = new FormControl([], { nonNullable: true });
  filteredAccessibility: FormControl<IOption[]> = new FormControl([], { nonNullable: true });

  showDeleteDialog: boolean = false;

  showStickyDivider: boolean = false;

  constructor(
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private notificationService: NotificationService
  ) {
    this.init();
  }

  async init() {
    try {
      this.loading = true;
      await this.getIndicators();
    } catch (error) {
      this.notificationService.showError('Failed to Load Indicators');
    } finally {
      this.loading = false;
    }
  }

  async getIndicators() {
    const response = await this.indicatorDefinitionsApiService.getAll(
      false,
      this.searchValue,
      Object.values(IndicatorCategory).filter(type => this.filteredCategory.value.find(opt => type === opt.value)),
      Object.values(IndicatorDefinitionAccessibility).filter(type => this.filteredAccessibility.value.find(opt => type === opt.value)),
      undefined,
      this.currentPagination.currentPage,
      this.currentPagination.pageSize
    );

    this.currentPagination = {
      currentPage: response.result.currentPage,
      totalPages: response.result.totalPages,
      pageSize: response.result.pageSize,
      totalCount: response.result.totalCount,
      hasPrevious: response.result.hasPrevious,
      hasNext: response.result.hasNext,
    };

    this.indicatorDefinitions = _.sortBy(response.result.items, [pi => pi.name.toLowerCase()]).map(indicator => ({
      id: indicator.id,
      name: indicator.name,
      category: indicator.category,
      valueType: indicator.valueType,
      description: indicator.description,
      tableValueDefinition: indicator.tableValueDefinition,
      numericValueDefinition: indicator.numericValueDefinition,
      frameworkStandards: indicator.frameworkStandards,
      sectors: indicator.sectors,
      isIndicatorFromSystem: indicator.isIndicatorFromSystem,
      rawDataSourceIds: indicator.indicatorRawDataSourceIds,
      indicatorDefinitionDataSourceIds: indicator.indicatorDefinitionAsDataSourceIds,
      organizationAccessIds: indicator.organizationAccessIds,
      recordDuration: indicator.recordDuration,
      accessibility: this.setAccessibility(indicator),
      createdBy: indicator.createdBy,
    }));
  }

  filterMenuProvider = (): IMenuItem[] => [
    { id: 'clear', label: 'Clear all filters', onClick: () => this.clearFilters() },
  ];

  clearFilters() {
    this.filteredCategory.setValue([]);
    this.filteredAccessibility.setValue([]);
    this.handleFilterChange();
  }

  handleFilterChange() {
    this.currentPagination = { ...this.currentPagination, currentPage: 1, hasNext: false, hasPrevious: false };
    this.getIndicators();
  }

  handlePaginationChange(newPaginationData: PaginationUi) {
    this.currentPagination = newPaginationData;
    this.getIndicators();
  }

  onSearchValueChange(newSearchValue: string) {
    this.searchValue = newSearchValue;

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  
    this.searchTimeout = setTimeout(() => {
      this.handleFilterChange();
    }, 500);
  }

  setAccessibility(indicatorDefinition: GetAllIndicatorDefinitionsQueryVm) {
    if (!indicatorDefinition.organizationAccessIds || indicatorDefinition.organizationAccessIds.length === 0) {
      return 'Not set';
    }
    if (
      indicatorDefinition.organizationAccessIds.length === 1 &&
      indicatorDefinition.organizationAccessIds.includes(Constants.GlobalKey)
    ) {
      return 'Global';
    }
    return 'Custom';
  }

  selectIndicator(indicatorId: string) {
    this.resetForm();
    this.editedIndicatorId = undefined;
    this.selectedIndicatorId = indicatorId;
  }

  closeSelectedIndicator() {
    this.selectedIndicatorId = undefined;
    this.getIndicators();
  }

  createIndicator() {
    this.editedIndicatorId = undefined;
    this.indicatorForm = {
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      category: new FormControl<IOption>(this.categoryOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
    };
    this.indicatorFormGroup = new FormGroup(this.indicatorForm);
  }

  async createIndicatorSubmit() {
    const category = Object.values(IndicatorCategory).find(type => type === this.indicatorForm?.category.value.value);
    if (!this.indicatorFormGroup || !this.indicatorFormGroup?.valid || !category) {
      this.cancelCreate();
      return;
    }

    const name = this.indicatorFormGroup.controls.name.value;
    this.resetForm();

    try {
      const response = await this.indicatorDefinitionsApiService.createIndicatorDefinition(
        new CreateIndicatorDefinitionCommandParams({
          name: name,
          category: category,
          valueType: IndicatorValueType.Numeric,
          description: '',
          numericValueDefinition: new IndicatorNumericValueDefinition({
            unit: 'Number',
            unitLabel: '',
            calculationType: CalculationType.Sum,
          }),
          frameworkStandards: '',
          sectors: [],
          indicatorRawDataSourceIds: [],
          indicatorDefinitionAsDataSourceIds: [],
          recordDuration: RecordDuration.DateFromTo,
        })
      );
      if (response.success) {
        this.getIndicators();
      }
    } catch (error) {
      this.notificationService.showError('Failed to create indicator');
    }
  }

  cancelCreate() {
    this.resetForm();
  }

  editIndicator(indicatorId: string) {
    this.editedIndicatorId = indicatorId;
    const indicator = this.indicatorDefinitions.find(i => i.id === indicatorId);
    if (indicator) {
      this.indicatorForm = {
        name: new FormControl<string>(indicator.name, { nonNullable: true }),
        category: new FormControl<IOption>(
          this.categoryOptions.find(opt => opt.value === indicator.category) || this.categoryOptions[0],
          { nonNullable: true }
        ),
      };
      this.indicatorFormGroup = new FormGroup(this.indicatorForm);
    }
  }

  async updateIndicatorSubmit() {
    const indicator = this.indicatorDefinitions.find(i => i.id === this.editedIndicatorId);
    if (!this.indicatorFormGroup || !this.indicatorFormGroup?.dirty || !this.indicatorFormGroup?.valid || !indicator) {
      this.cancelEdit();
      return;
    }

    const name = this.indicatorFormGroup.controls.name.value;
    const category = Object.values(IndicatorCategory).find(type => type === this.indicatorForm?.category.value.value);
    this.editedIndicatorId = undefined;
    this.resetForm();

    try {
      const response = await this.indicatorDefinitionsApiService.updateIndicatorDefinition(
        new UpdateIndicatorDefinitionCommandParams({
          id: indicator.id,
          name: name,
          description: indicator.description,
          frameworkStandards: indicator.frameworkStandards,
          category: category || indicator.category,
          sectors: indicator.sectors,
          tableValueDefinition: indicator.tableValueDefinition,
          numericValueDefinition: indicator.numericValueDefinition,
          indicatorRawDataSourceIds: indicator.rawDataSourceIds,
          indicatorDefinitionAsDataSourceIds: indicator.indicatorDefinitionDataSourceIds,
          organizationAccessIds: indicator.organizationAccessIds,
          valueType: indicator.valueType,
          recordDuration: indicator.recordDuration,
        })
      );
      if (response.success) {
        this.getIndicators();
      }
    } catch (error) {
      this.notificationService.showError('Failed to update indicator');
    }
  }

  cancelEdit() {
    this.editedIndicatorId = undefined;
    this.resetForm();
  }

  initiateDelete() {
    this.showDeleteDialog = true;
  }

  async deleteIndicatorSubmit() {
    this.showDeleteDialog = false;
    const definition = this.indicatorDefinitions.find(def => def.id === this.selectedIndicatorId);
    if (definition) {
      const response = await this.indicatorDefinitionsApiService.deleteIndicatorDefinition(definition.id);
      if (response.success) {
        this.selectedIndicatorId = undefined;
        this.getIndicators();
      } else {
        this.notificationService.showError('Failed to delete indicator', response.message);
      }
    }
  }

  cancelDelete() {
    this.showDeleteDialog = false;
  }

  resetForm() {
    this.indicatorForm = undefined;
    this.indicatorFormGroup = undefined;
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    this.showStickyDivider = tabEntry.intersectionRatio === 0;
  }

  handleSelectCategory(category: IOption) {
    if (this.indicatorForm && this.indicatorForm.category.value !== category) {
      this.indicatorForm.category.setValue(category);
      this.indicatorForm.category.markAsDirty();
    }
  }
}
