import { IOption, PdfWritingSystemOptions } from '../../../static-data/options';
import {
  CoverPhoto,
  CreateReportBasedOnTemplateCommandParams,
  CreateReportCommandParams,
  PdfWritingSystem,
  ReportVersion,
  SubOrganizationVm,
  TimePeriod,
  UpdateReportCommandParams,
} from '../../../../api-client/report-api.generated';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportApiService } from 'src/app/api-client/report-api/report-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { IReportDataUi } from './edit-form/edit-form.component';
import { ITimePeriodData } from '../../../shared/components/time-period/time-period.component';
import { TemplateType, VisibleForm } from './report-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { NotificationLinkUi } from 'src/app/shared/ui/notification/notification.component';
import { Router } from '@angular/router';
import { ReportStateService } from '../report-state.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import {
  findNestedOption,
  ITreeOption,
} from 'src/app/shared/ui/select/select-tree-options/select-tree-options.component';

export interface TemplateData {
  type: TemplateType;
  id?: string;
  title?: string;
  description?: string;
  shortDescription?: string;
  backgroundPhotoUrl?: string;
}

function getSubOrganization(subOrganizations: SubOrganizationVm[]): ITreeOption[] {
  return subOrganizations.map(so => ({
    value: so.id || '',
    label: so.name,
    children: getSubOrganization(so.subOrganizations),
  }));
}

@Injectable()
export class ReportFormStateService {
  visibleForm?: VisibleForm;
  showReportForm() {
    return this.visibleForm === VisibleForm.ReportForm;
  }

  showTemplateList() {
    return this.visibleForm === VisibleForm.TemplateList;
  }

  isLoading = false;

  reportId?: string;
  reportVersion?: ReportVersion;
  templateData?: TemplateData;

  fc: IReportDataUi = {
    title: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    reportScope: new FormControl<ITreeOption | null>(null),
    inludeSubOrgs: new FormControl<boolean>(true, { nonNullable: true }),
    pdfWritingSystem: new FormControl<IOption | null>(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    timePeriod: new FormControl<ITimePeriodData | null>(null, { nonNullable: true, validators: [Validators.required] }),
    coverPhoto: new FormControl<IUploadedPhoto | null>(null),
    coverPhotoSquare: new FormControl<IUploadedPhoto | null>(null),
  };

  formgroup = new FormGroup(this.fc);
  organizationSubOrganizationOptions: ITreeOption[] = [];
  pdfWritingSystemOptions = PdfWritingSystemOptions;

  constructor(
    private appInfo: AppInfoService,
    private appApiService: ReportAppApiService,
    private domSanitizer: DomSanitizer,
    private reportApiService: ReportApiService,
    private notificationService: NotificationService,
    private router: Router,
    private reportStateService: ReportStateService,
    protected organizationApiService: OrganizationApiService
  ) {}

  selectTemplate(templateData: TemplateData) {
    this.templateData = templateData;
    this.visibleForm = VisibleForm.ReportForm;
  }

  async initReport(reportId?: string, reportVersion?: ReportVersion) {
    var organizationContextId = this.appInfo.organizationContext.value;
    if (!organizationContextId) {
      throw Error('Organization context not set.');
    }
    if (reportId) this.isLoading = true;
    var organizationListResponse = await this.organizationApiService.getSubOrganizationTree(organizationContextId);
    this.organizationSubOrganizationOptions = organizationListResponse.result.map(o => ({
      value: '',
      label: o.name,
      children: getSubOrganization(o.subOrganizations),
    }));
    this.reportVersion = reportVersion;
    this.reportId = reportId;
    if (reportId) {
      const { result } = await this.reportApiService.getByReportId(reportId);
      this.reportVersion = result.reportVersion;
      this.fc.title.setValue(result.name);
      this.fc.reportScope.setValue(
        (result.subOrganizationId &&
          findNestedOption(this.organizationSubOrganizationOptions, result.subOrganizationId)) ||
          this.organizationSubOrganizationOptions[0]
      );
      this.fc.inludeSubOrgs.setValue(result.includeNestedSubOrganizations);
      this.fc.timePeriod.setValue(result.timePeriod as ITimePeriodData);
      this.fc.pdfWritingSystem.setValue(
        this.pdfWritingSystemOptions.find(p => p.value === result.pdfWritingSystem) || null
      );
      this.fc.coverPhoto.setValue(result.coverPhoto ? this.getLogo(result.coverPhoto) : null);
      this.fc.coverPhotoSquare.setValue(result.coverPhotoSquare ? this.getLogo(result.coverPhotoSquare) : null);
      this.isLoading = false;
    } else {
      this.fc.pdfWritingSystem.setValue(PdfWritingSystemOptions[0]);
      this.fc.reportScope.setValue(this.organizationSubOrganizationOptions[0]);
    }
    if (!this.fc.reportScope.value?.children?.length) {
      this.fc.inludeSubOrgs.setValue(false);
      this.fc.inludeSubOrgs.disable();
    }
  }

  getSubOgrnizationId() {
    return this.fc.reportScope.value?.value ? this.fc.reportScope.value?.value : undefined;
  }

  async editReport() {
    const timePeriodData = this.fc.timePeriod.value;
    const coverPhotoValue = this.fc.coverPhoto.value;
    const coverPhotoSquareValue = this.fc.coverPhotoSquare.value;
    const pdfWritingSystemValue = this.fc.pdfWritingSystem.value;

    if (!this.reportId || !pdfWritingSystemValue) {
      throw Error('Data not valid');
    }
    const result = await this.reportApiService.edit(
      new UpdateReportCommandParams({
        reportId: this.reportId,
        name: this.fc.title.value,
        subOrganizationId: this.getSubOgrnizationId(),
        includeNestedSubOrganizations: this.fc.inludeSubOrgs.value,
        pdfWritingSystem: PdfWritingSystem[pdfWritingSystemValue.value as keyof typeof PdfWritingSystem],
        coverPhoto: coverPhotoValue
          ? new CoverPhoto({
              blobName: coverPhotoValue.blobName || '',
              blobThumbnailName: coverPhotoValue.blobThumbnailName,
              fileName: coverPhotoValue.fileName,
            })
          : undefined,
        coverPhotoSquare: coverPhotoSquareValue
          ? new CoverPhoto({
              blobName: coverPhotoSquareValue.blobName || '',
              blobThumbnailName: coverPhotoSquareValue.blobThumbnailName,
              fileName: coverPhotoSquareValue.fileName,
            })
          : undefined,
        timePeriod: timePeriodData ? new TimePeriod(timePeriodData) : undefined,
      })
    );
    this.clearState();
  }

  async saveReportFromTemplate() {
    const timePeriod = this.fc.timePeriod.value;
    const coverPhotoValue = this.fc.coverPhoto.value;
    const coverPhotoSquareValue = this.fc.coverPhotoSquare.value;
    const pdfWritingSystemValue = this.fc.pdfWritingSystem.value;

    if (!timePeriod || !pdfWritingSystemValue) {
      throw Error('Data not valid');
    }

    if (this.templateData?.id) {
      const result = await this.reportApiService.createBaseOnTemplate(
        new CreateReportBasedOnTemplateCommandParams({
          templateId: this.templateData?.id || '',
          name: this.fc.title.value,
          subOrganizationId: this.getSubOgrnizationId(),
          includeNestedSubOrganizations: this.fc.inludeSubOrgs.value,
          pdfWritingSystem: PdfWritingSystem[pdfWritingSystemValue.value as keyof typeof PdfWritingSystem],
          coverPhoto: coverPhotoValue
            ? new CoverPhoto({
                blobName: coverPhotoValue.blobName || '',
                blobThumbnailName: coverPhotoValue.blobThumbnailName,
                fileName: coverPhotoValue.fileName,
              })
            : undefined,
          coverPhotoSquare: coverPhotoSquareValue
            ? new CoverPhoto({
                blobName: coverPhotoSquareValue.blobName || '',
                blobThumbnailName: coverPhotoSquareValue.blobThumbnailName,
                fileName: coverPhotoSquareValue.fileName,
              })
            : undefined,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period || 0,
          customStart: timePeriod.customStart,
          customEnd: timePeriod.customEnd,
        })
      );
      this.onCreateReportSucess(result.result.id);
    } else {
      if (!this.reportVersion) {
        throw Error('Report version not set.');
      }
      const result = await this.reportApiService.create(
        new CreateReportCommandParams({
          reportVersion: this.reportVersion,
          name: this.fc.title.value,
          subOrganizationId: this.getSubOgrnizationId(),
          includeNestedSubOrganizations: this.fc.inludeSubOrgs.value,
          pdfWritingSystem: PdfWritingSystem[pdfWritingSystemValue.value as keyof typeof PdfWritingSystem],
          coverPhoto: coverPhotoValue
            ? new CoverPhoto({
                blobName: coverPhotoValue.blobName || '',
                blobThumbnailName: coverPhotoValue.blobThumbnailName,
                fileName: coverPhotoValue.fileName,
              })
            : undefined,
          coverPhotoSquare: coverPhotoSquareValue
            ? new CoverPhoto({
                blobName: coverPhotoSquareValue.blobName || '',
                blobThumbnailName: coverPhotoSquareValue.blobThumbnailName,
                fileName: coverPhotoSquareValue.fileName,
              })
            : undefined,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period || 0,
          customStart: timePeriod.customStart,
          customEnd: timePeriod.customEnd,
        })
      );
      this.onCreateReportSucess(result.result.id);
    }
  }

  onCreateReportSucess(reportId: string) {
    const link: NotificationLinkUi = {
      text: 'Go to report.',
      routerLink: ['/', 'report', reportId],
    };
    this.notificationService.showSuccess('Report created successfully', '', link);
    if (this.router.url === '/report') {
      this.reportStateService.loadReports();
    }
    this.clearState();
  }

  uploadCoverPhoto(file: File) {
    this.appApiService
      .uploadImage({
        fileName: file.name,
        data: file,
      })
      .subscribe(result => {
        let objectUrl = URL.createObjectURL(file);
        var cp: IUploadedPhoto = {
          url: this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl),
          fileName: file.name,
          blobName: result.blobName,
          blobThumbnailName: result.blobThumbnailName,
        };
        this.fc.coverPhoto.setValue(cp);
        this.fc.coverPhoto.markAsDirty();
      });
  }

  removeCoverPhoto() {
    this.fc.coverPhoto.setValue(null);
    this.fc.coverPhoto.markAsDirty();
  }

  uploadCoverSquarePhoto(file: File) {
    this.appApiService
      .uploadImage({
        fileName: file.name,
        data: file,
      })
      .subscribe(result => {
        let objectUrl = URL.createObjectURL(file);
        var cp: IUploadedPhoto = {
          url: this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl),
          fileName: file.name,
          blobName: result.blobName,
          blobThumbnailName: result.blobThumbnailName,
        };
        this.fc.coverPhotoSquare.setValue(cp);
        this.fc.coverPhotoSquare.markAsDirty();
      });
  }

  removeCoverSquarePhoto() {
    this.fc.coverPhotoSquare.setValue(null);
    this.fc.coverPhotoSquare.markAsDirty();
  }
  clearState() {
    this.visibleForm = VisibleForm.TemplateList;
    this.templateData = undefined;
  }

  getLogo(logo: CoverPhoto): IUploadedPhoto {
    const blobUrl = this.appApiService.getBlobUrl(logo.blobThumbnailName || logo.blobName);
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    return {
      url: safeUrl,
      fileName: logo.fileName,
      blobName: logo.blobName,
      blobThumbnailName: logo.blobThumbnailName,
    };
  }
}
