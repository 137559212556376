import { Component } from '@angular/core';
import { DynamicContentBase, IContentCommonActions, IContentCommonStates } from '../../dynamic-content-data';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { FormControl } from '@angular/forms';
import { MovedIndicator } from './indicator-content-set/indicator-content-set.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { IndicatorContentSubstrateDataUi } from './indicator-content-substrate-data/indicator-content-substrate-data.component';

export enum VisibleValueColumn {
  PreviousValue = 'PreviousValue',
  CurrentValue = 'CurrentValue',
  TargetValue = 'TargetValue',
}

export interface IndicatorContentValuesUi {
  indicatorId?: string;
  currentValue: IndicatorContentValueUi;
  metadata: IndicatorContentValueMetaDataUi;
  previousValue: IndicatorContentValueUi;
  targetValue: IndicatorContentValueUi;
  substrateData?: IndicatorContentSubstrateDataUi;
  referenceStandards?: string;
}

interface IndicatorContentSingleValueMetaDataUi {
  label?: string;
  type?: string;
  unit?: string;
  unitDescription?: string;
  groupLabel?: string;
  isVisibleInReport?: boolean;
}

interface IndicatorContentValueMetaDataUi extends IndicatorContentSingleValueMetaDataUi {
  tableColumnsMetadata?: IndicatorContentSingleValueMetaDataUi[];
}

interface IndicatorContentSingleValueUi {
  type?: string;
  value?: string | number;
  unitDetails?: string;
  errorMessage?: string;
}

interface IndicatorContentValueUi extends IndicatorContentSingleValueUi {
  tableRows?: IndicatorContentTableValueRowUi[];
}

export interface IndicatorContentTableValueRowUi {
  values?: IndicatorContentSingleValueUi[];
}

export interface IIndicatorContentActions extends IContentCommonActions {
  addIndicator: (indicatorId: string) => void;
  deleteIndicator: (indicatorId: string) => void;
}

export interface IIndicatorContentStates extends IContentCommonStates {
  indicatorPeriodLabels: IndicatorPeriodLabelsUi;
  indicatorOptions: IGroupedOptions[];
  indicatorValues: IndicatorContentValuesUi[];
}

export interface IIndicatorContentData {
  indicatorType?: IndicatorContentType;
  visibleColumns: VisibleValueColumn[];
  indicatorIds: string[];
}

export enum IndicatorContentType {
  IndicatorSet = 'IndicatorSet',
  IndicatorTableValue = 'IndicatorTableValue',
}

export interface IndicatorPeriodLabelsUi {
  targetLabel: string;
  currentLabel: string;
  previousLabel: string;
}

@Component({
  selector: 'esg-indicator-content',
  templateUrl: './indicator-content.component.html',
  styleUrls: ['../content-item.component.scss', './indicator-content.component.scss'],
})
export class IndicatorContentComponent extends DynamicContentBase<
  IIndicatorContentActions,
  IIndicatorContentStates,
  IIndicatorContentData
> {
  indicatorSelectCollapsed: boolean = true;
  indicatorTypeEnum = IndicatorContentType;
  setColumnOptions: IOption[] = [
    { value: VisibleValueColumn.PreviousValue, label: 'Previous' },
    { value: VisibleValueColumn.CurrentValue, label: 'Current' },
    { value: VisibleValueColumn.TargetValue, label: 'Target' },
  ];
  tableValueColumnOptions: IOption[] = this.setColumnOptions.filter(
    opt => opt.value !== VisibleValueColumn.TargetValue
  );
  visibleColumns: FormControl<IOption[]> = new FormControl(this.setColumnOptions, { nonNullable: true });

  get indicatorLabels(): string {
    return this.states.indicatorValues
      .map(indicator => indicator.metadata.label)
      .filter(label => label)
      .join(', ');
  }

  ngOnInit() {
    this.setColumnOptions = [
      { value: VisibleValueColumn.PreviousValue, label: this.states.indicatorPeriodLabels.previousLabel },
      { value: VisibleValueColumn.CurrentValue, label: this.states.indicatorPeriodLabels.currentLabel },
      { value: VisibleValueColumn.TargetValue, label: this.states.indicatorPeriodLabels.targetLabel },
    ];
    this.tableValueColumnOptions = this.setColumnOptions.filter(opt => opt.value !== VisibleValueColumn.TargetValue);
    if (this.data) {
      const currentData = this.data;
      this.visibleColumns.setValue(
        this.setColumnOptions.filter(option => currentData.visibleColumns.some(column => column === option.value))
      );
      this.visibleColumns.valueChanges.subscribe(columns => {
        const updatedIndicator = {
          ...this.data,
          visibleColumns: columns.map(column => column.value as VisibleValueColumn),
          indicatorIds: this.data?.indicatorIds || [],
        };
        this.onChange(
          this.contentId,
          this.isVisibleInReport,
          {
            indicatorPeriodLabels: this.states.indicatorPeriodLabels,
            indicatorOptions: this.states.indicatorOptions,
            indicatorValues: this.states.indicatorValues,
          },
          updatedIndicator
        );
      });
    }
  }

  handleComponentClick() {
    this.onSetActive.emit(true);
  }

  handleOutsideClick() {
    this.onSetActive.emit(false);
  }

  handleOnDelete() {
    this.onDelete.emit(this.contentId);
  }

  handleOnUpdateVisibility(visible: boolean) {
    this.onChange(
      this.contentId,
      visible,
      {
        indicatorPeriodLabels: this.states.indicatorPeriodLabels,
        indicatorOptions: this.states.indicatorOptions,
        indicatorValues: this.states.indicatorValues,
      },
      this.data
    );
  }

  handleOnAddIndicator(option: IOption) {
    this.actions.addIndicator(option.value);
  }

  handleOnIndicatorSelectClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.indicatorSelectCollapsed = !this.indicatorSelectCollapsed;
  }

  handleOnIndicatorSelectCollapse(collapse: boolean) {
    this.indicatorSelectCollapsed = collapse;
  }

  handleOnAddIndicatorToSet(indicatorId: string) {
    this.actions.addIndicator(indicatorId);
  }

  handleOnRemoveIndicatorFromSet(indicatorId: string) {
    this.actions.deleteIndicator(indicatorId);
  }

  handleOnMoveIndicatorInSet(event: MovedIndicator) {
    const indicatorSet = this.states.indicatorValues;
    const indicatorIds = this.data?.indicatorIds || [];

    const updatedIndicatorRows = [...indicatorSet];
    const updatedIndicatorIds = [...indicatorIds];
    moveItemInArray(updatedIndicatorRows, event.prevIndex, event.newIndex);
    moveItemInArray(updatedIndicatorIds, event.prevIndex, event.newIndex);

    this.onChange(
      this.contentId,
      this.isVisibleInReport,
      {
        indicatorPeriodLabels: this.states.indicatorPeriodLabels,
        indicatorOptions: this.states.indicatorOptions,
        indicatorValues: updatedIndicatorRows,
      },
      {
        indicatorIds: updatedIndicatorIds,
        indicatorType: this.data?.indicatorType,
        visibleColumns: this.data?.visibleColumns || [],
      }
    );
  }
}
