import { IUploadedPhoto } from '../shared/ui/upload-picture/upload-picture.component';
import { IGroupedOptions } from '../static-data/options';
import { ContentActionsTypes, ContentDataTypes, ContentStatesTypes, ContentTypes } from './content-configuration';
import {
  IndicatorContentValuesUi,
  IndicatorPeriodLabelsUi,
} from './content-item/indicator-content/indicator-content.component';
import { IPictureContentData } from './content-item/picture/picture.component';

export class DynamicContent {
  constructor(
    public contentId: string,
    public contentType: ContentTypes,
    public isVisibleInReport: boolean,
    public actions: ContentActionsTypes,
    public states: ContentStatesTypes,
    public data?: ContentDataTypes
  ) {}
}

export const DynamicComponentFactory = (actions: {
  uploadPhoto: (componentId: string) => (file?: File) => void;
  getUploadedPhoto: (blobName: string) => IUploadedPhoto;
  generateText?: (question: string) => Promise<string>;
  addIndicator?: (componentId: string) => (indicatorId: string) => void;
  deleteIndicator?: (componentId: string) => (indicatorId: string) => void;
}) => ({
  [ContentTypes.Text]: (id: string, isVisibleInReport: boolean, content?: string) => {
    return new DynamicContent(
      id,
      ContentTypes.Text,
      isVisibleInReport,
      {
        generateText: actions.generateText,
      },
      {},
      content ? JSON.parse(content) : undefined
    );
  },
  [ContentTypes.Picture]: (id: string, isVisibleInReport: boolean, content?: string) => {
    const componentContent: IPictureContentData = content ? JSON.parse(content) : undefined;
    const uploadedPhoto =
      componentContent && componentContent.blobName ? actions.getUploadedPhoto(componentContent.blobName) : undefined;
    return new DynamicContent(
      id,
      ContentTypes.Picture,
      isVisibleInReport,
      {
        uploadPhoto: actions.uploadPhoto(id),
      },
      { uploadedPhoto },
      componentContent
    );
  },
  [ContentTypes.ImportFromExcel]: (id: string, isVisibleInReport: boolean, content?: string) => {
    return new DynamicContent(
      id,
      ContentTypes.ImportFromExcel,
      isVisibleInReport,
      {},
      {},
      content ? JSON.parse(content) : undefined
    );
  },
  [ContentTypes.Indicator]: (
    id: string,
    isVisibleInReport: boolean,
    indicatorPeriodLabels: IndicatorPeriodLabelsUi,
    indicatorOptions: IGroupedOptions[],
    indicatorValues: IndicatorContentValuesUi[],
    content?: string
  ) => {
    return new DynamicContent(
      id,
      ContentTypes.Indicator,
      isVisibleInReport,
      {
        addIndicator: actions.addIndicator ? actions.addIndicator(id) : undefined,
        deleteIndicator: actions.deleteIndicator ? actions.deleteIndicator(id) : undefined,
      },
      {
        indicatorPeriodLabels: indicatorPeriodLabels,
        indicatorOptions: indicatorOptions,
        indicatorValues: indicatorValues,
      },
      content ? JSON.parse(content) : undefined
    );
  },
});
