import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TimePeriod } from 'src/api-client/report-api.generated';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { SectionContentViewModel } from 'src/app/shared/components/section-content-preview/section-content-preview.component';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import { IKeyfigures, MainLevel, TopicItem } from '../../report-preview-state.service';
import { IToCList } from '../../shared/components/table-of-contents/table-of-contents.component';
import {
  IndicatorContentType,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';

export interface MainTopicPreviewUi {
  id?: string;
  name?: string;
  topics: TopicPreviewUi[];
}

interface TopicPreviewUi {
  id?: string;
  name?: string;
  indicatorViewModels: SectionContentViewModel[];
}

interface ContentUi {
  textHtml: string;
  blobUrl: string;
  altText: string;
  title: string;
  tableConfig?: any;
  rows?: any;
  indicatorType?: IndicatorContentType;
  visibleColumns?: VisibleValueColumn[];
  keyFigures?: IKeyfigures[];
}

@Injectable()
export class MainTopicFeedStateService {
  reportId: string = '';
  activeTopicId: string = '';
  activeTopicItemId: string = '';
  mainTopicPreviewUiState: MainTopicPreviewUi = {
    id: '',
    name: '',
    topics: [],
  };
  topicTabs?: ITabItem[] = undefined;
  topicItemTabs?: IToCList;
  topicInitialized: boolean = false;

  topicPaddingTop: number = 60;
  changeContentOn: number = 500;
  observationBuffer = 1;

  tocRootmargin: string = '-200px 0px 0px 0px'; // fixed when top = 200px

  // observing top: changeContentOn, height: observationBuffer
  get contentRootmargin() {
    return (
      -this.changeContentOn +
      'px 0px ' +
      (this.changeContentOn + this.observationBuffer - window.innerHeight) +
      'px 0px'
    );
  }

  fixedToc: boolean = false;

  constructor(private router: Router, private appApiService: ReportAppApiService) {}

  initMainlevel(reportId: string, mainLevel: MainLevel, timePeriod?: TimePeriod) {
    this.reportId = reportId;
    this.topicTabs = [];
    this.mainTopicPreviewUiState = {
      id: mainLevel.id,
      name: mainLevel.name,
      topics:
        mainLevel.topics?.map(t => {
          this.topicTabs &&
            this.topicTabs.push({
              value: t.id || '',
              label: t.name,
              link: [mainLevel.id || '', t.id || ''],
            });
          return {
            id: t.id || '',
            name: t.name || '',
            indicatorViewModels:
              t.topicItems?.map(topicItem => this.mapToIndicatorViewModel(topicItem, timePeriod)) || [],
          };
        }) || [],
    };
    mainLevel?.topics?.length && mainLevel.topics[0].id && this.setTopicItemTabs(mainLevel.topics[0].id);
  }

  setTopicItemTabs(topicId: string, activeStart: boolean = true) {
    const topic = this.mainTopicPreviewUiState.topics?.find(topic => topic.id === topicId);

    if (topic) {
      this.topicItemTabs = {
        label: topic.name || '',
        items:
          topic.indicatorViewModels?.map(topicItem => ({
            value: topicItem.id || '',
            label: topicItem.name,
            link: ['./'],
            fragment: topicItem.id,
          })) || [],
      };
      this.setUrl(
        topicId,
        topic.indicatorViewModels?.length
          ? topic.indicatorViewModels[activeStart ? 0 : topic.indicatorViewModels.length - 1].id
          : undefined
      );
    }
  }

  resetTabs() {
    this.mainTopicPreviewUiState.id = undefined;
    this.topicTabs = undefined;
    this.topicItemTabs = undefined;
  }

  onTopicInit() {
    if (!this.topicInitialized) {
      this.onContentLinkClick(this.activeTopicId);
      this.topicInitialized = true;
    }
  }

  onContentLinkClick(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setUrl(topicId: string, topicItemId?: string) {
    this.activeTopicId = topicId;
    this.activeTopicItemId = topicItemId || '';
    const urlParts = this.router.url.split('/');
    const rootPage = urlParts[1];
    this.router.navigate([`/${rootPage}`, this.reportId, this.mainTopicPreviewUiState.id, topicId], {
      fragment: topicItemId,
      queryParamsHandling: 'preserve',
    });
  }

  handleTopicChange(topicEntry: IntersectionObserverEntry) {
    if (!this.topicTabs?.length) return;

    const currentTopicIndex = this.topicTabs.findIndex(item => item.value === this.activeTopicId);
    if (topicEntry.boundingClientRect.top > this.changeContentOn && currentTopicIndex) {
      this.setTopicItemTabs(this.topicTabs[currentTopicIndex - 1].value, false);
    } else if (
      topicEntry.boundingClientRect.bottom < this.changeContentOn &&
      currentTopicIndex !== undefined &&
      currentTopicIndex < this.topicTabs.length - 1
    ) {
      this.setTopicItemTabs(this.topicTabs[currentTopicIndex + 1].value, true);
    }
  }

  handleTopicItemChange(topicItemEntry: IntersectionObserverEntry) {
    if (!this.topicItemTabs?.items?.length) return;

    const currentTopicItemIndex = this.topicItemTabs.items.findIndex(item => item.value === this.activeTopicItemId);
    if (
      topicItemEntry.boundingClientRect.top > this.changeContentOn &&
      currentTopicItemIndex &&
      currentTopicItemIndex > 0
    ) {
      this.setUrl(this.activeTopicId, this.topicItemTabs.items[currentTopicItemIndex - 1].value);
    } else if (
      topicItemEntry.boundingClientRect.bottom < this.changeContentOn &&
      currentTopicItemIndex !== undefined &&
      currentTopicItemIndex < this.topicItemTabs.items.length - 1
    ) {
      this.setUrl(this.activeTopicId, this.topicItemTabs.items[currentTopicItemIndex + 1].value);
    }
  }

  handleOnTocIntersection(tocItemEntry: IntersectionObserverEntry) {
    this.fixedToc = tocItemEntry.intersectionRatio === 0;
  }

  mapToIndicatorViewModel(indicator: TopicItem, timePeriod?: TimePeriod): SectionContentViewModel {
    let contents: ContentUi[] = [];
    indicator?.components?.forEach(c => {
      let content = c.content ? JSON.parse(c.content || '') : undefined;
      if (content) {
        let blobUrl = content.blobName ? this.appApiService.getBlobUrl(content.blobName) : '';
        const indicatorIds: string[] = content?.indicatorIds || [];
        const keyFigures = this.getOrderedIndicatorValues(indicator.dataValues || [], indicatorIds);
        contents?.push({
          textHtml: content.textHtml ? content.textHtml : '',
          blobUrl: blobUrl,
          altText: content.altText || '',
          title: c?.title || '',
          tableConfig: content.tableConfig || undefined,
          rows: content.rows || [],
          indicatorType: content.indicatorType,
          visibleColumns: content.visibleColumns,
          keyFigures: keyFigures,
        });
      }
    });
    return {
      id: indicator.id,
      name: indicator.name,
      type: indicator.type,
      timePeriod: timePeriod,
      contents: contents,
    } as SectionContentViewModel;
  }

  getOrderedIndicatorValues(indicatorValues: IKeyfigures[], indicatorIds: string[]): IKeyfigures[] {
    return indicatorIds.map(id => indicatorValues.find(value => value.indicatorId === id)!).filter(Boolean);
  }
}
