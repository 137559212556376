import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-error-icon',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
      <circle cx="7" cy="7" r="7" fill="#FF3B30" />
      <path
        d="M7 8.13516C6.52469 8.13516 6.28086 7.90304 6.26852 7.43879L6.14815 2.77571C6.14198 2.5524 6.21605 2.36729 6.37037 2.22037C6.53086 2.07346 6.73765 2 6.99074 2C7.23765 2 7.44136 2.0764 7.60185 2.22919C7.76852 2.3761 7.84877 2.56121 7.84259 2.78452L7.7037 7.43879C7.69753 7.90304 7.46296 8.13516 7 8.13516ZM7 11C6.7284 11 6.49383 10.9119 6.2963 10.7356C6.09877 10.5534 6 10.3359 6 10.0833C6 9.83056 6.09877 9.61606 6.2963 9.43977C6.49383 9.25759 6.7284 9.1665 7 9.1665C7.2716 9.1665 7.50617 9.25465 7.7037 9.43095C7.90123 9.60725 8 9.82468 8 10.0833C8 10.3418 7.89815 10.5593 7.69444 10.7356C7.49691 10.9119 7.26543 11 7 11Z"
        fill="#F5F5F5" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class ErrorIconComponent {
  @Input() size: number = 16;
}
