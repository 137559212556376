<esg-side-menu class="side" [goBackUrl]="'/report'" [backButtonLabel]="'Back to all reports'" [showBackButton]="useNewMainNavigation">
  <div top class="top-section">
    <div class="title">{{ state.reportData?.name }}</div>
    <span class="organization-name">
      {{
        state.reportData?.subOrganizationId ? state.reportData?.subOrganizationName : state.reportData?.organizationName
      }}
    </span>
  </div>
  <div class="list drop-item-list" cdkDropList (cdkDropListDropped)="dropMainLevel($event)">
    <esg-main-level-item
      *ngFor="let mainLevel of reportData.mainLevels"
      class="drop-item"
      [mainLevelData]="mainLevel"
      [isLoading]="state.loadedMainTopics.includes(mainLevel.id || '')"
      (onUpdateMainLevel)="state.updateMainLevel($event)"
      (onAddTopic)="state.addTopic($event)"
      (onDeleteMainLevel)="handleDeleteMainLevel($event)">
      <div class="topic-list" cdkDropList (cdkDropListDropped)="dropTopic($event, mainLevel)">
        <esg-topic-item
          *ngFor="let topic of mainLevel.topics"
          [reportId]="state.reportData?.id || ''"
          [mainLevelId]="mainLevel.id || ''"
          [topicData]="topic"
          [isLoading]="state.loadedTopics.includes(topic.id || '')"
          (onSelectTopic)="handleSelectTopic($event)"
          (onEditTopic)="handleEditTopic($event)"
          (onDeleteTopic)="handleDeleteTopic($event)"
          (onDeactivateTopic)="handleDeactivateTopic($event)"></esg-topic-item>
      </div>
    </esg-main-level-item>

    <button *ngIf="!newMainLevel" class="add-main-level-btn" (click)="handleAddMainLevel($event)">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.5 8.5L13.5 7.5L8.5 7.5L8.5 2.5L7.5 2.5L7.5 7.5L2.5 7.5L2.5 8.5L7.5 8.5L7.5 13.5L8.5 13.5L8.5 8.5L13.5 8.5Z"
          fill="#0071E3" />
      </svg>
      <span>Create Main Topic</span>
    </button>

    <div *ngIf="newMainLevel" class="add-main-level-edit">
      <esg-textbox-form
        [fc]="newMainLevel"
        placeholder="Write a main topic name"
        name="add-main-topic"
        [autoFocus]="true"
        (onEnter)="handleSubmitAddNewMainLevel()"></esg-textbox-form>
    </div>
  </div>

  <div bottom class="bottom-section">
    <hr />
    <div class="tools-menu">
      <esg-report-menu-item
        *ngIf="state.reportData?.reportVersion === reportVersionEnum.V2"
        label="Update Calculations"
        (onClick)="handleCalculateIndicators()">
        <esg-icon-update start class="menu-item-icon"></esg-icon-update>
      </esg-report-menu-item>
      <esg-report-menu-item label="Report’s Website" (onClick)="handleHtmlReport()">
        <esg-window-icon start class="menu-item-icon"></esg-window-icon>
        <esg-icon-ext-link end class="menu-item-icon-end"></esg-icon-ext-link>
      </esg-report-menu-item>
      <esg-report-menu-item label="Create PDF" (onClick)="hanldeOpenCreatePdfContextMenu($event)">
        <esg-document-icon start class="menu-item-icon"></esg-document-icon>
        <div
          *ngIf="createPdfContextMenuOpen"
          end
          class="custom-contex-menu"
          esg-on-click-outside
          (onClickOutside)="handleCloseCreatePdfContextMenu()">
          <div class="custom-contex-menu-options">
            <div class="menu-option" (click)="handleCreatePdfReport()">Full ESG Report</div>
            <div class="menu-option" (click)="handleCreatePdfExecutiveSummaryReport()">Executive Summary</div>
          </div>
        </div>
      </esg-report-menu-item>
      <esg-report-menu-item *ngIf="appInfoState.showCreateTemplate" label="Save Report as Template" (onClick)="handleSaveReportAsTemplate()">
        <esg-save-template start class="menu-item-icon"></esg-save-template>
      </esg-report-menu-item>
      <esg-report-menu-item *ngIf="appInfoState.showCreateReport" label="Report Settings" (onClick)="handleReportSettings()">
        <esg-settings-icon start class="menu-item-icon"></esg-settings-icon>
      </esg-report-menu-item>
    </div>
  </div>
</esg-side-menu>

<esg-confirm-dialog
  *ngIf="removeMainLevelData"
  [title]="removeMainLevelTitle"
  [message]="removeMainLevelMessage"
  (onCancel)="removeMainLevelData = undefined"
  (onSubmit)="handleDeleteMainLevelSubmit()"></esg-confirm-dialog>
<esg-confirm-dialog
  *ngIf="removeTopicData"
  [title]="removeTopicTitle"
  [message]="removeTopicMessage"
  (onCancel)="removeTopicData = undefined"
  (onSubmit)="handleDeleteTopicSubmit()"></esg-confirm-dialog>

<esg-template-form
  *ngIf="templateFormDialogOpen"
  [reportId]="reportData.id"
  (onClose)="handleCloseTemplateFormDialog()"
  (onSubmit)="handleSavedAsTemplateSuccess()"></esg-template-form>

<esg-report-form
  *ngIf="reportFormDialogOpen"
  [reportId]="reportData.id"
  (onClose)="handleCloseReportFormDialog()"
  (onSubmit)="state.loadReportData(reportData.id)"></esg-report-form>
