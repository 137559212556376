<div class="toolbar">
  <div class="column-select">
    <button class="column-select-button" (click)="handleOnColumnSelectorToggle($event)" [disabled]="noData">
      Show/hide columns
    </button>
    <esg-select-options
      *ngIf="columnSelectorOpen"
      class="column-select-options"
      [selectedValues]="visibleColumns.value"
      [options]="columnOptions"
      (onChange)="handleSelectOption($event)"></esg-select-options>
  </div>
  <div *ngIf="hasVisibilityOption" class="visible-toggle">
    <esg-toggle-switch [checked]="isVisible" (onCheck)="handleUpdateVisibility()"></esg-toggle-switch>
    Visible
  </div>
  <button (click)="handleOnDelete()">
    <esg-delete-icon></esg-delete-icon>
  </button>
</div>
