import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-warning-filled-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M3.19954 13.0084H12.7945C13.0095 13.0084 13.179 12.9347 13.3031 12.7873C13.4313 12.6442 13.4953 12.4758 13.4953 12.2821C13.4953 12.1473 13.4623 12.0147 13.3961 11.8842L8.58933 3.34523C8.52317 3.22734 8.43634 3.14313 8.32883 3.0926C8.22546 3.03787 8.11589 3.0105 8.00011 3.0105C7.88434 3.0105 7.7727 3.03787 7.66519 3.0926C7.55768 3.14313 7.47085 3.22734 7.40469 3.34523L2.59792 11.8779C2.56484 11.9452 2.54003 12.0147 2.52349 12.0863C2.51109 12.1579 2.50488 12.2231 2.50488 12.2821C2.50488 12.4758 2.56691 12.6442 2.69095 12.7873C2.815 12.9347 2.98453 13.0084 3.19954 13.0084ZM8.00011 9.73681C7.68173 9.73681 7.5184 9.5705 7.51013 9.23787L7.4295 5.89681C7.42537 5.73681 7.47499 5.60418 7.57836 5.49892C7.68586 5.39366 7.82438 5.34102 7.99391 5.34102C8.1593 5.34102 8.29575 5.39576 8.40326 5.50523C8.5149 5.6105 8.56866 5.74313 8.56452 5.90313L8.47149 9.23787C8.46735 9.5705 8.31023 9.73681 8.00011 9.73681ZM8.00011 11.7894C7.81818 11.7894 7.66105 11.7263 7.52874 11.6C7.39642 11.4694 7.33026 11.3137 7.33026 11.1326C7.33026 10.9516 7.39642 10.7979 7.52874 10.6716C7.66105 10.541 7.81818 10.4758 8.00011 10.4758C8.18205 10.4758 8.33917 10.5389 8.47149 10.6652C8.6038 10.7916 8.66996 10.9473 8.66996 11.1326C8.66996 11.3179 8.60173 11.4737 8.46528 11.6C8.33297 11.7263 8.17791 11.7894 8.00011 11.7894Z"
        fill="#FF9500" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class WarningFilledIconComponent {
  @Input() size: number = 16;
}
