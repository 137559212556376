import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportDetailsStateService } from '../report-details-state.service';
import { TopicContentStateService } from './topic-content-state.service';

@Component({
  selector: 'esg-topic-content',
  templateUrl: './topic-content.component.html',
  styleUrl: './topic-content.component.scss',
  providers: [TopicContentStateService],
})
export class TopicContentComponent {
  topicSub?: Subscription;

  constructor(
    public state: TopicContentStateService,
    public reportState: ReportDetailsStateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.topicSub = this.route.params.subscribe(params => {
      const selectedTopicId = params['topicid'];
      if (selectedTopicId) {
        this.reportState.clearTopicDetails();
        this.reportState.setTopicDetails(selectedTopicId);
        this.reportState.reportData &&
          this.reportState.selectedTopic &&
          this.state.init(
            this.reportState.reportData.id,
            this.reportState.selectedTopic.mainLevelId,
            this.reportState.selectedTopic.id,
            this.reportState.reportData.timePeriod
          );
      } else if (!selectedTopicId && this.reportState.selectedTopic) this.reportState.clearTopicDetails();
    });
  }

  shouldShowTooltip(): boolean {
    const topicName = this.reportState?.selectedTopic?.name || '';
    return topicName.length > 35;
  }

  ngOnDestroy(): void {
    this.topicSub?.unsubscribe();
  }
}
