<div *ngIf="showSearchValue" class="search-box section">
  <esg-textbox
  [required]="true"
  placeholder="Search"
  [value]="searchValue ?? ''"
  [hasPrefixIcon]="true"
  (onChange)="handleSearchValueChange($event)">
  <esg-search-icon prefix-icon></esg-search-icon>
  </esg-textbox>
</div>
<div *ngIf="showFilter" class="filter section">
  <span class="section-label">Filter by</span>
  <ng-content></ng-content>
</div>
<div class="section page-size">
  <span class="section-label">Show</span>
  <div class="section-content">
    <esg-select
      [value]="sizeOption"
      size="small"
      [options]="pageSizeOptions"
      [fitLongestLabel]="false"
      (onChange)="setPageSize($event)"></esg-select>
    per page
  </div>
</div>
<div class="section page-navigation">
  <span class="section-label">Showing</span>
  <div class="section-content">
    <div class="page-arrows">
      <button class="arrow left" [disabled]="!paginationData.hasPrevious" (click)="handlePreviousPage()">
        <esg-chevron></esg-chevron>
      </button>
      <span class="range bold">{{ getDisplayedItemsRange }}</span>
      <button class="arrow" [disabled]="!paginationData.hasNext" (click)="handleNextPage()">
        <esg-chevron></esg-chevron>
      </button>
    </div>
    of&nbsp;
    <span class="bold">{{ getFormattedNumber(paginationData.totalCount.toString(), 0, 0) }}</span>
    &nbsp;Records
  </div>
</div>
<div *ngIf="menuItems.length" class="context-menu section">
  <esg-context-menu [options]="menuItems" type="horizontal" menuPosition="BottomLeft"></esg-context-menu>
</div>
