import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-indicator-content-toolbar',
  templateUrl: './indicator-content-toolbar.component.html',
  styleUrl: './indicator-content-toolbar.component.scss',
})
export class IndicatorContentToolbarComponent {
  @Input({ required: true }) visibleColumns!: FormControl<IOption[]>;
  @Input() columnOptions: IOption[] = [];
  @Input() noData: boolean = false;
  @Input() isVisible: boolean = true;
  @Input() hasVisibilityOption: boolean = false;
  @Output() onDelete = new EventEmitter();
  @Output() onUpdateVisibility = new EventEmitter<boolean>();

  columnSelectorOpen = false;

  constructor() {}

  ngOnInit(): void {}

  handleOnColumnSelectorToggle(event: Event) {
    event.stopPropagation();
    this.columnSelectorOpen = !this.columnSelectorOpen;
  }

  handleOnDelete() {
    this.onDelete.emit();
  }

  handleUpdateVisibility() {
    this.onUpdateVisibility.emit(!this.isVisible);
  }

  handleSelectOption(option: IOption) {
    if (this.visibleColumns.value.some(o => o.value == option.value)) {
      this.visibleColumns.setValue(this.visibleColumns.value.filter(o => o.value !== option.value));
    } else {
      this.visibleColumns.setValue([...this.visibleColumns.value, option]);
    }
    this.visibleColumns.markAsDirty();
  }
}
